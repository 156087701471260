import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "../context/UserContext";

const Header = ({ setOpenMenu, blank }) => {
  let { navHeader } = useUserContext();

  return (
    <div className="header-container">
      {!blank && (
        <div className="hamburger-wrapper">
          <FontAwesomeIcon
            icon={faBars}
            className="btn-hamburger"
            onClick={(event) => setOpenMenu(true)}
          />
        </div>
      )}
      <div className="header-title">{navHeader || ""}</div>
    </div>
  );
};

export default Header;
