import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import SortTable from "../utils/SortTable";
import Config from "../config/Config";
import Loader from "../utils/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DBManifestManagementModal from "./DBManifestManagementModal";

const columns = [
  {
    Header: "ID",
    accessor: "id",
    sticky: "left",
  },
  {
    Header: "Script Name",
    accessor: "scriptName",
  },
  {
    Header: "Enabled",
    accessor: "enabled",
    type: "boolean",
  },
  {
    Header: "Run Once",
    accessor: "runOnce",
    type: "boolean",
  },
  {
    Header: "DB Schema",
    accessor: "db_schema",
  },
  {
    Header: "Date Added",
    accessor: "dateAdded",
    type: "date",
  },
  {
    Header: "User Added",
    accessor: "userAdded",
  },
  {
    Header: "Date Modified",
    accessor: "dateModified",
    type: "date",
  },
  {
    Header: "User Modified",
    accessor: "userModified",
  },
];

const defaultSorted = [{ id: "dateAdded", desc: true }];

const DBManifestManagement = () => {
  let { db_manifest_api_root, db_manifest_api_key } = Config;
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [scripts, setScripts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [scriptInfo, setScriptInfo] = useState({});

  const handleRefreshScripts = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    axios
      .get(db_manifest_api_root + "/script", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": db_manifest_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the db scripts: " +
              response.data.error,
          });
        } else if (response.data.length > 0) {
          setScripts(response.data);
          setAPIMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no db scripts.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAPIMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving db scripts!",
        });
      });
  }, [db_manifest_api_root, db_manifest_api_key]);

  useEffect(() => {
    handleRefreshScripts();
  }, [handleRefreshScripts]);

  const handleEditClick = (event, row) => {
    setModalAction("Edit");
    setScriptInfo(row.values);
    setModalOpen(true);
  };

  const handleAddClick = (event) => {
    setModalAction("Add");
    setScriptInfo({
      scriptName: "",
      db_schema: "",
      enabled: true,
      runOnce: false,
    });
    setModalOpen(true);
  };

  const handleDeleteClick = (event, row) => {
    if (window.confirm("Are you sure you want to delete this db script?")) {
      axios
        .delete(`${db_manifest_api_root}/script/${row.values.id}`, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": db_manifest_api_key,
          },
        })
        .then((response) => {
          handleRefreshScripts();
        })
        .catch((error) => {
          console.log(error);
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "Error deleting db script!",
          });
        });
    }
  };

  return (
    <div className="message-center-container">
      <div className="tool-title">DB Manifest Management</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The DB Manifest Management tool can be used to manage the database
          scripts that are executed as a part of the FSM-IT Devs release
          process.
        </div>
        <div className="tool-feature-list">
          <div className="tool-feature-label">Tool Features:</div>
          <ul>
            <li>Database scripts can be added, deleted, and modified.</li>
            <li>
              Enablement, run-once, database schema, and script name are
              properties that can be modified.
            </li>
          </ul>
        </div>
      </div>
      <div className="btn-add-message-wrap">
        <button onClick={(event) => handleAddClick()}>
          <FontAwesomeIcon icon={faPlus} className="btn-add-message" />
          Add Script
        </button>
      </div>
      <div className="table-container">
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={scripts}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              editClick={handleEditClick}
              deleteClick={handleDeleteClick}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
      <DBManifestManagementModal
        modalOpen={modalOpen}
        closeModal={(event) => setModalOpen(false)}
        action={modalAction}
        scriptInfo={scriptInfo}
        refreshScripts={handleRefreshScripts}
      />
    </div>
  );
};

export default DBManifestManagement;
