import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import Config from "../config/Config";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

export const UserContext = createContext();
export const UserContextConsumer = UserContext.Consumer;
export const useUserContext = () => useContext(UserContext);

const UserContextProvider = (props) => {
  const [userUT, setUserUT] = useState();
  const { ut_users_api_root, ut_users_api_key } = Config;
  const [roles, setRoles] = useState();
  const [navHeader, setNavHeader] = useState();
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const userName = accounts[0] && accounts[0].name;
  const netID = accounts[0] && accounts[0].username.split("@")[0];
  const [accessToken, setAccessToken] = useState();
  const [userPhoto, setUserPhoto] = useState(null);
  const [userTitle, setUserTitle] = useState(null);

  useEffect(() => {
    if (accounts[0] && instance) {
      const request = {
        ...Config.loginRequest,
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(request)
        .then((response) => {
          setAccessToken(response.accessToken);
        })
        .catch((e) => {
          instance.acquireTokenPopup(request).then((response) => {
            setAccessToken(response.accessToken);
          });
        });
    }
  }, [accounts, instance]);

  useEffect(() => {
    if (accessToken) {
      const headers = new Headers();
      const bearer = `Bearer ${accessToken}`;

      headers.append("Authorization", bearer);

      const options = {
        method: "GET",
        headers: headers,
      };

      fetch(`${Config.graphConfig.graphMeEndpoint}`, options)
        .then((response) => response.json())
        .then((data) => setUserTitle(data.jobTitle))
        .catch((error) => console.log(error));

      fetch(`${Config.graphConfig.graphMeEndpoint}/photo/$value`, options)
        .then((response) => response.blob())
        .then((data) => setUserPhoto(URL.createObjectURL(data)))
        .catch((error) => console.log(error));
    }
  }, [accessToken]);

  useEffect(() => {
    setUserUT({
      netid: netID,
      username: userName,
      title: userTitle,
      photo: userPhoto,
    });
  }, [userName, netID, userTitle, userPhoto]);

  useEffect(() => {
    if (userUT && userUT.netid) {
      if (Config.rbac_enabled)
        axios
          .get(ut_users_api_root + "/users", {
            headers: {
              "x-api-key": ut_users_api_key,
            },
          })
          .then((response) => {
            setRoles(
              response.data.filter((user) => user.netid === userUT.netid)[0]
                .roles || ["visitor"]
            );
          })
          .catch((error) => {
            console.log("Could not get user roles: " + error);
          });
      else setRoles(["visitor"]);
    } else setRoles(["visitor"]);
  }, [userUT, ut_users_api_root, ut_users_api_key]);

  function handleLogin(instance) {
    instance.loginRedirect(Config.loginRequest).catch((e) => {
      console.error(e);
    });
  }

  function handleLogout(instance) {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }

  return (
    <UserContext.Provider
      value={{
        userUT,
        roles,
        navHeader,
        isAuthenticated,
        accessToken,
        actions: {
          setNavHeader: setNavHeader,
          logout: () => handleLogout(instance),
          login: () => handleLogin(instance),
        },
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
