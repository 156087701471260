import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { faTimes, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Config from "../config/Config";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "white",
    zIndex: "1",
    maxHeight: "100vh",
    overflow: "scroll",
    width: "400px",
    maxWidth: "calc(100% - 60px)",
  },
  overlay: {
    backgroundColor: "rgba(5, 7, 10, .8)",
    zIndex: "4001",
  },
};

const PortalReportsManagementModal = ({
  modalOpen,
  closeModal,
  action,
  userInfo,
  refreshUsers,
  roles,
  rolesMessage,
}) => {
  const [roleMatrix, setRoleMatrix] = useState([]);
  const [rolesRemain, setRolesRemain] = useState(true);
  const [noRoles, setNoRoles] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    error: false,
    message: "",
  });
  let { portal_api_root, portal_api_key } = Config;

  useEffect(() => {
    if (userInfo.ROLES)
      setRoleMatrix(
        userInfo.ROLES.map((role) => {
          return role.ROLE_ID;
        })
      );
  }, [userInfo]);

  useEffect(() => {
    var count = null;
    roles.forEach((role) => {
      if (roleMatrix.includes(role.ROLE_ID)) count++;
    });
    if (count === roles.length) setRolesRemain(false);
    else setRolesRemain(true);

    if (roleMatrix.length === 0) setNoRoles(true);
    else setNoRoles(false);
  }, [roleMatrix, roles]);

  const handleClose = (event) => {
    event.preventDefault();
    setSubmitStatus({
      loading: false,
      error: false,
      message: "",
    });
    closeModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setSubmitStatus({ loading: true });

    let sRoles = [...new Set(roleMatrix)].join(", "); // remove duplicates and join

    let messageBody = {
      roleids: sRoles,
    };

    axios
      .post(portal_api_root + `/portal/netid/${userInfo.NETID}`, messageBody, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": portal_api_key,
        },
      })
      .then((response) => {
        setSubmitStatus({
          loading: false,
          error: false,
          message: "The user roles were successfully updated!",
        });
        refreshUsers();
      })
      .catch((error) => {
        console.log(error);
        setSubmitStatus({
          loading: false,
          error: true,
          message: "There was an error saving the user roles!",
        });
      });
  };

  const handleAddRole = (role) =>
    setRoleMatrix((roleMatrix) => [...roleMatrix, role]);

  const handleRemoveRole = (event, role) => {
    if (window.confirm("Are you sure you want to remove this role?")) {
      setRoleMatrix(
        roleMatrix.filter((roleMatrixElem) => roleMatrixElem !== role)
      );
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={`${action} Message`}
      closeTimeoutMS={0}
    >
      <div className="modal-inner">
        <div className="modal-close-wrap">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={(event) => handleClose(event)}
          />
        </div>
        <div className="modal-heading">{action} User</div>
        <div className="modal-body-container">
          <form onSubmit={(event) => handleSubmit(event)}>
            <div className="form-group">
              <div className="form-label static">NetID:</div>
              <div className="static-input">{userInfo.NETID}</div>
            </div>
            <div className="form-group">
              <div className="form-label static">Name:</div>
              <div className="static-input">
                {userInfo.FIRST_NAME} {userInfo.LAST_NAME}
              </div>
            </div>
            <div className="modal-heading">Roles</div>
            {rolesMessage.ready ? (
              noRoles ? (
                <div className="no-roles-msg">
                  User does not have any roles assigned.
                </div>
              ) : (
                <div className="modal-role-group">
                  {roleMatrix.map((role) => (
                    <div
                      className="modal-role-wrap"
                      key={role}
                      onClick={(event) => handleRemoveRole(event, role)}
                    >
                      <div className="modal-role-label">{role}</div>
                      <div className="modal-role-remove">
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    </div>
                  ))}
                </div>
              )
            ) : (
              <div className="no-roles-msg">{rolesMessage.messageText}</div>
            )}
            <div className="modal-role-add-wrap">
              {rolesRemain ? (
                <div className="form-group">
                  <div className="form-label">Add Role:</div>
                  <div className="custom-dd-wrap">
                    <select
                      value={"init"}
                      onChange={(event) => handleAddRole(event.target.value)}
                    >
                      <option value="init" key="init" disabled>
                        Select a role.
                      </option>
                      {roles.map((role) => {
                        return !roleMatrix.includes(role.ROLE_ID) ? (
                          <option value={role.ROLE_ID} key={role.ROLE_ID}>
                            {role.ROLE_ID} -- {role.ROLE_NAME}
                          </option>
                        ) : null;
                      })}
                    </select>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="btn-group two">
              <button className="btn-sm">
                Submit
                {submitStatus.loading ? (
                  <span>
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      className="btn-processing fa-spin"
                    />
                  </span>
                ) : null}
              </button>
              <button
                className="btn-sm secondary"
                onClick={(event) => handleClose(event)}
              >
                Close
              </button>
            </div>
            {submitStatus.message !== "" ? (
              <div
                className={`submit-msg ${submitStatus.error ? "error" : ""}`}
              >
                {submitStatus.message}
              </div>
            ) : null}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default PortalReportsManagementModal;
