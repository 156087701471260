import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { faTimes, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Config from "../../config/Config";
import { useUserContext } from "../../context/UserContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../utils/Loader";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "white",
    zIndex: "1",
    maxHeight: "90vh",
    overflow: "scroll",
    width: "400px",
    maxWidth: "calc(100% - 60px)",
  },
  overlay: {
    backgroundColor: "rgba(5, 7, 10, .8)",
    zIndex: "4001",
  },
};

const ProfessorshipsModal = ({
  modalOpen,
  modalAction,
  closeModal,
  holderInfo,
  refreshHolders,
}) => {
  const [modalHolderInfo, setModalHolderInfo] = useState({});
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    error: false,
    message: "",
  });
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  let { netid } = useUserContext().userUT || {};

  useEffect(() => {
    if (holderInfo.ID) {
      axios
        .get(
          `${fao_admin_api_root}/faoadmin/professorships/holders/${holderInfo.ID}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": fao_admin_api_key,
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            // Internal Error
            setAPIMessage({
              loading: false,
              error: true,
              ready: false,
              messageText:
                "There was an error retrieving the professorships holder: " +
                response.data.error,
            });
          } else if (response.data.data.length > 0) {
            setModalHolderInfo(response.data.data[0]);
            setAPIMessage({
              loading: false,
              error: false,
              ready: true,
              messageText: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "Error retrieving professorships holder!",
          });
        });
    } else {
      if (modalAction === "Add")
        setAPIMessage({
          loading: false,
          error: false,
          ready: true,
          messageText: "",
        });
    }
  }, [holderInfo, fao_admin_api_root, fao_admin_api_key, modalAction]);

  const handleClose = (event) => {
    event.preventDefault();
    setModalHolderInfo({});
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });
    setSubmitStatus({
      loading: false,
      error: false,
      message: "",
    });
    closeModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitStatus({ loading: true });

    if (modalAction === "Add") {
      let message = {
        prof_id: modalHolderInfo.PROF_ID,
        professorship_name: modalHolderInfo.PROFESSORSHIP_NAME,
        employee_id: modalHolderInfo.EMPLOYEE_ID,
        display_flag: modalHolderInfo.DISPLAY_FLAG,
        current_flag: modalHolderInfo.CURRENT_FLAG,
        coterminous: modalHolderInfo.COTERMINOUS,
        reappt_elig: modalHolderInfo.REAPPT_ELIG,
        start_date: modalHolderInfo.START_DATE || new Date(),
        end_date: modalHolderInfo.END_DATE || new Date(),
        create_user: netid,
      };

      axios
        .post(
          `${fao_admin_api_root}/faoadmin/professorships/holders`,
          message,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": fao_admin_api_key,
            },
          }
        )
        .then((response) => {
          if (!response.data.error) {
            setSubmitStatus({
              loading: false,
              error: false,
              message: "The professorship holder was successfully added!",
            });
            refreshHolders();
          } else {
            // Internal Error
            setSubmitStatus({
              loading: false,
              error: true,
              message:
                "There was an error adding the professorship holder: " +
                response.data.error,
            });
          }
        })
        .catch((error) => {
          setSubmitStatus({
            loading: false,
            error: true,
            message: "There was an error adding the professorship holder!",
          });
          console.log(error);
        });
    } else if (modalAction === "Edit") {
      let message = {
        prof_id: modalHolderInfo.PROF_ID,
        display_flag: modalHolderInfo.DISPLAY_FLAG,
        current_flag: modalHolderInfo.CURRENT_FLAG,
        coterminous: modalHolderInfo.COTERMINOUS,
        reappt_elig: modalHolderInfo.REAPPT_ELIG,
        start_date: modalHolderInfo.START_DATE || new Date(),
        end_date: modalHolderInfo.END_DATE || new Date(),
        modify_user: netid,
      };

      axios
        .put(
          `${fao_admin_api_root}/faoadmin/professorships/holders/${holderInfo.ID}`,
          message,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": fao_admin_api_key,
            },
          }
        )
        .then((response) => {
          if (!response.data.error) {
            setSubmitStatus({
              loading: false,
              error: false,
              message: "The professorship holder was successfully updated!",
            });
            refreshHolders();
          } else {
            // Internal Error
            setSubmitStatus({
              loading: false,
              error: true,
              message:
                "There was an error updating the professorship holder: " +
                response.data.error,
            });
          }
        })
        .catch((error) => {
          setSubmitStatus({
            loading: false,
            error: true,
            message: "There was an error updating the professorship holder!",
          });
          console.log(error);
        });
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel={`${modalAction} Professorships Holder`}
      closeTimeoutMS={0}
    >
      <div className="modal-inner">
        <div className="modal-close-wrap">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={(event) => handleClose(event)}
          />
        </div>
        <div className="modal-heading">{modalAction} Professorships Holder</div>
        {apiMessage.ready ? (
          <div className="modal-body-container">
            <form onSubmit={(event) => handleSubmit(event)}>
              {modalHolderInfo.ID && (
                <div className="form-group">
                  <div className="form-label">Holder ID:</div>
                  <input
                    type="text"
                    required
                    placeholder=""
                    autoComplete="off"
                    disabled
                    value={modalHolderInfo.ID}
                  />
                </div>
              )}
              <div className="form-group">
                <div className="form-label">Professorship ID:</div>
                <input
                  type="text"
                  required
                  placeholder=""
                  autoComplete="off"
                  value={modalHolderInfo.PROF_ID || ""}
                  onChange={(e) =>
                    setModalHolderInfo({
                      ...modalHolderInfo,
                      PROF_ID: e.target.value,
                    })
                  }
                />
              </div>
              {modalAction === "Add" && (
                <div className="form-group">
                  <div className="form-label">Professorship Name:</div>
                  <input
                    type="text"
                    required
                    placeholder=""
                    autoComplete="off"
                    value={modalHolderInfo.PROFESSORSHIP_NAME || ""}
                    onChange={(e) =>
                      setModalHolderInfo({
                        ...modalHolderInfo,
                        PROFESSORSHIP_NAME: e.target.value,
                      })
                    }
                  />
                </div>
              )}
              {modalAction === "Add" && (
                <div className="form-group">
                  <div className="form-label">Employee ID:</div>
                  <input
                    type="text"
                    required
                    placeholder=""
                    autoComplete="off"
                    value={modalHolderInfo.EMPLOYEE_ID || ""}
                    onChange={(e) =>
                      setModalHolderInfo({
                        ...modalHolderInfo,
                        EMPLOYEE_ID: e.target.value,
                      })
                    }
                  />
                </div>
              )}
              <div className="form-group">
                <div className="form-label">Start Date:</div>
                <DatePicker
                  selected={
                    modalHolderInfo.START_DATE
                      ? new Date(modalHolderInfo.START_DATE)
                      : new Date()
                  }
                  onChange={(date) =>
                    setModalHolderInfo({
                      ...modalHolderInfo,
                      START_DATE: date,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <div className="form-label">End Date:</div>
                <DatePicker
                  selected={
                    modalHolderInfo.END_DATE
                      ? new Date(modalHolderInfo.END_DATE)
                      : new Date()
                  }
                  onChange={(date) =>
                    setModalHolderInfo({
                      ...modalHolderInfo,
                      END_DATE: date,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <div className="form-label">Display Flag:</div>
                <div className="custom-dd-wrap">
                  <select
                    value={modalHolderInfo.DISPLAY_FLAG || "init"}
                    onChange={(e) =>
                      setModalHolderInfo({
                        ...modalHolderInfo,
                        DISPLAY_FLAG: e.target.value,
                      })
                    }
                  >
                    <option value="init" disabled>
                      Select a display flag.
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="form-label">Current Flag:</div>
                <div className="custom-dd-wrap">
                  <select
                    value={modalHolderInfo.CURRENT_FLAG || "init"}
                    onChange={(e) =>
                      setModalHolderInfo({
                        ...modalHolderInfo,
                        CURRENT_FLAG: e.target.value,
                      })
                    }
                  >
                    <option value="init" disabled>
                      Select a current flag.
                    </option>
                    <option value="c">Current</option>
                    <option value="p">Past</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="form-label">Coterminous:</div>
                <div className="custom-dd-wrap">
                  <select
                    value={modalHolderInfo.COTERMINOUS || "init"}
                    onChange={(e) =>
                      setModalHolderInfo({
                        ...modalHolderInfo,
                        COTERMINOUS: e.target.value,
                      })
                    }
                  >
                    <option value="init" disabled>
                      Select a coterminous status.
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="form-label">Reappointment Eligibilty:</div>
                <div className="custom-dd-wrap">
                  <select
                    value={modalHolderInfo.REAPPT_ELIG || "init"}
                    onChange={(e) =>
                      setModalHolderInfo({
                        ...modalHolderInfo,
                        REAPPT_ELIG: e.target.value,
                      })
                    }
                  >
                    <option value="init" disabled>
                      Select a reappointment eligibilty.
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </div>
              <div className="btn-group two">
                <button type="submit" className="btn-sm">
                  Submit
                  {submitStatus.loading && (
                    <span>
                      <FontAwesomeIcon
                        icon={faCircleNotch}
                        className="btn-processing fa-spin"
                      />
                    </span>
                  )}
                </button>
                <button
                  type="button"
                  className="btn-sm secondary"
                  onClick={(event) => handleClose(event)}
                >
                  Close
                </button>
              </div>
              {submitStatus.message !== "" && (
                <div
                  className={`submit-msg ${submitStatus.error ? "error" : ""}`}
                >
                  {submitStatus.message}
                </div>
              )}
            </form>
          </div>
        ) : (
          <Loader message={apiMessage} />
        )}
      </div>
    </Modal>
  );
};

export default ProfessorshipsModal;
