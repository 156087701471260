import React from "react";
import ToolMenu from "../../utils/ToolMenu";
import { Route, Switch } from "react-router-dom";
import ProfessorshipsAdmins from "./ProfessorshipsAdmin";
import ProfessorshipsNoteTypesAdmins from "./ProfessorshipsNoteTypesAdmin";
import ProfessorshipsHoldersAdmin from "./ProfessorshipsHoldersAdmin";

const HomeFAOProfessorships = () => {
  return (
    <div className="main-content-outer">
      <div className="main-content-inner constrain-1260">
        <ToolMenu app="faoProfessorships" appURL="/fao/professorships" />
        <div className="app-content-container">
          <Switch>
            <Route
              exact
              path="/fao/professorships"
              component={ProfessorshipsAdmins}
            />
            <Route
              path="/fao/professorships/note-types"
              component={ProfessorshipsNoteTypesAdmins}
            />
            <Route
              path="/fao/professorships/holders"
              component={ProfessorshipsHoldersAdmin}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default HomeFAOProfessorships;
