import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { faTimes, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Config from "../../config/Config";
import { useUserContext } from "../../context/UserContext";
import Loader from "../../utils/Loader";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "white",
    zIndex: "1",
    maxHeight: "90vh",
    overflow: "scroll",
    width: "400px",
    maxWidth: "calc(100% - 60px)",
  },
  overlay: {
    backgroundColor: "rgba(5, 7, 10, .8)",
    zIndex: "4001",
  },
};

const FacultyNoteTypesModal = ({
  modalOpen,
  modalAction,
  closeModal,
  noteTypeInfo,
  refreshNoteTypes,
}) => {
  const [modalNoteTypeInfo, setModalNoteTypeInfo] = useState({});
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    error: false,
    message: "",
  });
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  let { netid } = useUserContext().userUT || {};

  useEffect(() => {
    if (noteTypeInfo.NOTE_TYPE_ID) {
      axios
        .get(
          `${fao_admin_api_root}/faoadmin/faculty/notetypes/${noteTypeInfo.NOTE_TYPE_ID}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": fao_admin_api_key,
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            // Internal Error
            setAPIMessage({
              loading: false,
              error: true,
              ready: false,
              messageText:
                "There was an error retrieving the faculty note type: " +
                response.data.error,
            });
          } else if (response.data.data.length > 0) {
            setModalNoteTypeInfo(response.data.data[0]);
            setAPIMessage({
              loading: false,
              error: false,
              ready: true,
              messageText: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "Error retrieving faculty note type!",
          });
        });
    } else {
      if (modalAction === "Add")
        setAPIMessage({
          loading: false,
          error: false,
          ready: true,
          messageText: "",
        });
    }
  }, [noteTypeInfo, fao_admin_api_root, fao_admin_api_key, modalAction]);

  const handleClose = (event) => {
    event.preventDefault();
    setModalNoteTypeInfo({});
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });
    setSubmitStatus({
      loading: false,
      error: false,
      message: "",
    });
    closeModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitStatus({ loading: true });

    if (modalAction === "Add") {
      let message = {
        create_user: netid,
        note_type: modalNoteTypeInfo.NOTE_TYPE,
        active_flag: modalNoteTypeInfo.ACTIVE_FLAG === "Active" ? "a" : "i",
      };

      axios
        .post(`${fao_admin_api_root}/faoadmin/faculty/notetypes`, message, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": fao_admin_api_key,
          },
        })
        .then((response) => {
          if (!response.data.error) {
            setSubmitStatus({
              loading: false,
              error: false,
              message: "The faculty note type was successfully added!",
            });
            refreshNoteTypes();
          } else {
            // Internal Error
            setSubmitStatus({
              loading: false,
              error: true,
              message:
                "There was an error adding the faculty note type: " +
                response.data.error,
            });
          }
        })
        .catch((error) => {
          setSubmitStatus({
            loading: false,
            error: true,
            message: "There was an error adding the faculty note type!",
          });
          console.log(error);
        });
    } else if (modalAction === "Edit") {
      let message = {
        modify_user: netid,
        note_type: modalNoteTypeInfo.NOTE_TYPE,
        active_flag: modalNoteTypeInfo.ACTIVE_FLAG === "Active" ? "a" : "i",
      };

      axios
        .put(
          `${fao_admin_api_root}/faoadmin/faculty/notetypes/${noteTypeInfo.NOTE_TYPE_ID}`,
          message,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": fao_admin_api_key,
            },
          }
        )
        .then((response) => {
          if (!response.data.error) {
            setSubmitStatus({
              loading: false,
              error: false,
              message: "The faculty note type was successfully updated!",
            });
            refreshNoteTypes();
          } else {
            // Internal Error
            setSubmitStatus({
              loading: false,
              error: true,
              message:
                "There was an error updating the faculty note type: " +
                response.data.error,
            });
          }
        })
        .catch((error) => {
          setSubmitStatus({
            loading: false,
            error: true,
            message: "There was an error updating the faculty note type!",
          });
          console.log(error);
        });
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel={`${modalAction} Faculty Note Type`}
      closeTimeoutMS={0}
    >
      <div className="modal-inner">
        <div className="modal-close-wrap">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={(event) => handleClose(event)}
          />
        </div>
        <div className="modal-heading">{modalAction} Faculty Note Type</div>
        {apiMessage.ready ? (
          <div className="modal-body-container">
            <form onSubmit={(event) => handleSubmit(event)}>
              {noteTypeInfo.NOTE_TYPE_ID && (
                <div className="form-group">
                  <div className="form-label">Note Type ID:</div>
                  <input
                    type="text"
                    required
                    placeholder=""
                    autoComplete="off"
                    disabled
                    value={modalNoteTypeInfo.NOTE_TYPE_ID}
                  />
                </div>
              )}
              <div className="form-group">
                <div className="form-label">Note Type:</div>
                <input
                  type="text"
                  required
                  placeholder=""
                  autoComplete="off"
                  value={modalNoteTypeInfo.NOTE_TYPE || ""}
                  onChange={(e) =>
                    setModalNoteTypeInfo({
                      ...modalNoteTypeInfo,
                      NOTE_TYPE: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <div className="form-label">Active Flag:</div>
                <div className="custom-dd-wrap">
                  <select
                    value={modalNoteTypeInfo.ACTIVE_FLAG || "init"}
                    required
                    onChange={(e) =>
                      setModalNoteTypeInfo({
                        ...modalNoteTypeInfo,
                        ACTIVE_FLAG: e.target.value,
                      })
                    }
                  >
                    <option value="init" disabled>
                      Select active indicator.
                    </option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                </div>
              </div>
              <div className="btn-group two">
                <button type="submit" className="btn-sm">
                  Submit
                  {submitStatus.loading && (
                    <span>
                      <FontAwesomeIcon
                        icon={faCircleNotch}
                        className="btn-processing fa-spin"
                      />
                    </span>
                  )}
                </button>
                <button
                  type="button"
                  className="btn-sm secondary"
                  onClick={(event) => handleClose(event)}
                >
                  Close
                </button>
              </div>
              {submitStatus.message !== "" && (
                <div
                  className={`submit-msg ${submitStatus.error ? "error" : ""}`}
                >
                  {submitStatus.message}
                </div>
              )}
            </form>
          </div>
        ) : (
          <Loader message={apiMessage} />
        )}
      </div>
    </Modal>
  );
};

export default FacultyNoteTypesModal;
