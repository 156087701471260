import React from "react";
import ToolMenu from "../utils/ToolMenu";
import UserManagement from "./UserManagement";
import PortalReportsManagement from "./PortalReportsManagement";
import DBManifestManagement from "./DBManifestManagement";
import { Route, Switch } from "react-router-dom";
import Config from "../config/Config";
import ComingSoon from "../utils/ComingSoon";

const Admin = () => {
  return (
    <div className="main-content-outer">
      <div className="main-content-inner constrain-1260">
        <ToolMenu app="admin" appURL="/admin" />
        <div className="app-content-container">
          <Switch>
            <Route
              exact
              path="/admin"
              component={Config.rbac_enabled ? UserManagement : ComingSoon}
            />
            <Route
              exact
              path="/admin/portal-reports-management"
              component={PortalReportsManagement}
            />
            <Route
              exact
              path="/admin/db-manifest-management"
              component={DBManifestManagement}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Admin;
