import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import MenuSubnav from "./MenuSubnav";
import { NavMenu } from "../config/Apps";
import { useUserContext } from "../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import Can from "../auth/Can";

const Menu = ({ openMenu, setOpenMenu }) => {
  const [expand, setExpand] = useState({});
  const userContext = useUserContext();

  let { userUT, roles } = userContext;
  let { logout } = userContext.actions;

  const handleExpandToggle = (event, app) => {
    if (expand[app] === "expand") setExpand({ ...expand, [app]: "" });
    else setExpand({ ...expand, [app]: "expand" });
  };

  return (
    <>
      <div className={`menu-container ${openMenu ? "show" : "hide"}`}>
        <div className="menu-container-inner">
          {userUT ? (
            <div className="greeting-wrap">
              <img
                className="user-photo"
                src={userUT.photo}
                alt={userUT.username}
              />
              <div>
                <div className="user-name">{userUT.username}</div>
                <div className="user-title">{userUT.title}</div>
              </div>
            </div>
          ) : (
            <div className="greeting-wrap">
              <FontAwesomeIcon
                icon={faCircleNotch}
                className="fa-spin user-loader"
              />
            </div>
          )}
          <div className="menu-nav-wrap">
            {roles && (
              <Can
                roles={roles}
                perform="homepage:visit"
                yes={() => (
                  <div className="menu-nav-item-wrap">
                    <NavLink
                      exact
                      to="/"
                      onClick={(event) => setOpenMenu(false)}
                    >
                      <div className="menu-nav-label">Home</div>
                    </NavLink>
                  </div>
                )}
                no={() => null}
              />
            )}
            {roles && (
              <Can
                roles={roles}
                perform="admin-pages:visit"
                yes={() => (
                  <div className="menu-nav-item-wrap">
                    <NavLink
                      exact
                      to="/admin"
                      onClick={(event) => setOpenMenu(false)}
                    >
                      <div className="menu-nav-label">Admin</div>
                    </NavLink>
                  </div>
                )}
                no={() => null}
              />
            )}
            {NavMenu.map((app) => {
              return (
                <MenuSubnav
                  key={app.name}
                  expand={expand}
                  expandToggle={handleExpandToggle}
                  app={app}
                  setOpenMenu={setOpenMenu}
                />
              );
            })}
            <div className="menu-nav-item-wrap">
              <div
                className="menu-nav-label"
                onClick={(event) => {
                  if (window.confirm("Are you sure you want to log out?")) {
                    setOpenMenu(false);
                    logout();
                  }
                }}
              >
                Logout
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`screendoor ${openMenu ? "show" : "hide"}`}
        onClick={(event) => setOpenMenu(false)}
      ></div>
    </>
  );
};

export default Menu;
