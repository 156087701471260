const Local = {
  visitor: {
    static: ["homepage:visit"],
  },
  awome: {
    static: ["homepage:visit", "awome-pages:visit"],
  },
  fao: {
    static: ["homepage:visit", "fao-pages:visit"],
  },
  sysadmin: {
    static: [
      "homepage:visit",
      "admin-pages:visit",
      "fao-pages:visit",
      "awome-pages:visit",
    ],
  },
};

const Dev = {
  visitor: {
    static: ["homepage:visit"],
  },
  awome: {
    static: ["homepage:visit", "awome-pages:visit"],
  },
  fao: {
    static: ["homepage:visit", "fao-pages:visit"],
  },
  sysadmin: {
    static: [
      "homepage:visit",
      "admin-pages:visit",
      "fao-pages:visit",
      "awome-pages:visit",
    ],
  },
};

const UAT = {
  visitor: {
    static: ["homepage:visit"],
  },
  awome: {
    static: ["homepage:visit", "awome-pages:visit"],
  },
  fao: {
    static: ["homepage:visit", "fao-pages:visit"],
  },
  sysadmin: {
    static: [
      "homepage:visit",
      "admin-pages:visit",
      "fao-pages:visit",
      "awome-pages:visit",
    ],
  },
};

const Prod = {
  visitor: {
    static: ["homepage:visit"],
  },
  awome: {
    static: ["homepage:visit", "awome-pages:visit"],
  },
  fao: {
    static: ["homepage:visit", "fao-pages:visit"],
  },
  sysadmin: {
    static: [
      "homepage:visit",
      "admin-pages:visit",
      "fao-pages:visit",
      "awome-pages:visit",
    ],
  },
};

let rules = null;

switch (process.env.REACT_APP_ENV.toLowerCase()) {
  case "local":
    rules = Local;
    break;
  case "dev":
    rules = Dev;
    break;
  case "uat":
    rules = UAT;
    break;
  case "prod":
    rules = Prod;
    break;
  default:
    alert("Invalid RBAC config: " + process.env.REACT_APP_ENV.toLowerCase());
    break;
}

let rulesExport = {
  ...rules,
};

export default rulesExport;
