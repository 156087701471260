import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { faTimes, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Roles } from "../config/Apps";
import { useUserContext } from "../context/UserContext";
import Config from "../config/Config";
import axios from "axios";
import Loader from "../utils/Loader";
import DatePicker from "react-datepicker";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "white",
    zIndex: "1",
    maxHeight: "90vh",
    overflow: "scroll",
    width: "400px",
    maxWidth: "calc(100% - 60px)",
  },
  overlay: {
    backgroundColor: "rgba(5, 7, 10, .8)",
    zIndex: "4001",
  },
};

const UserManagementModal = ({
  modalOpen,
  closeModal,
  userInfo,
  refreshUsers,
  modalAction,
}) => {
  const [modalUserInfo, setModalUserInfo] = useState({});
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    error: false,
    message: "",
  });
  let { ut_users_api_root, ut_users_api_key } = Config;
  const context = useUserContext();

  let { netid } = context.userUT || {};

  useEffect(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    if (userInfo.netid) {
      axios
        .get(`${ut_users_api_root}/users/${userInfo.netid}`, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": ut_users_api_key,
          },
        })
        .then((response) => {
          if (response.data.error) {
            // Internal Error
            setAPIMessage({
              loading: false,
              error: true,
              ready: false,
              messageText:
                "There was an error retrieving the user: " +
                response.data.error,
            });
          } else if (response.data) {
            setModalUserInfo(response.data);
            setAPIMessage({
              loading: false,
              error: false,
              ready: true,
              messageText: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "Error retrieving user!",
          });
        });
    } else {
      if (modalAction === "Add") {
        setModalUserInfo(userInfo);
        setAPIMessage({
          loading: false,
          error: false,
          ready: true,
          messageText: "",
        });
      }
    }
  }, [userInfo, ut_users_api_root, ut_users_api_key, modalAction]);

  const handleClose = (event) => {
    event.preventDefault();
    setModalUserInfo({});
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });
    setSubmitStatus({
      loading: false,
      error: false,
      message: "",
    });
    closeModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitStatus({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    if (modalAction === "Add") {
      if (!modalUserInfo.roles) {
        alert("Please attach a role to the account.");
        setSubmitStatus({
          loading: false,
          error: true,
          message: "Role is required!",
        });
      } else {
        let message = {
          netid: modalUserInfo.netid,
          created_by: netid,
          name: modalUserInfo.name,
          roles: modalUserInfo.roles.sort(),
          start_date: modalUserInfo.start_date
            ? modalUserInfo.start_date
            : new Date(),
          end_date: modalUserInfo.end_date
            ? modalUserInfo.end_date
            : new Date(),
        };

        axios
          .post(`${ut_users_api_root}/users`, message, {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": ut_users_api_key,
            },
          })
          .then((response) => {
            if (!response.data.error) {
              setSubmitStatus({
                loading: false,
                error: false,
                message: "The user was successfully added!",
              });
              refreshUsers();
            } else {
              // Internal Error
              setSubmitStatus({
                loading: false,
                error: true,
                message:
                  "There was an error adding the user: " + response.data.error,
              });
            }
          })
          .catch((error) => {
            setSubmitStatus({
              loading: false,
              error: true,
              message: "There was an error adding the user!",
            });
            console.log(error);
          });
      }
    } else if (modalAction === "Edit") {
      let message = {
        netid: modalUserInfo.netid,
        modified_by: netid,
        name: modalUserInfo.name,
        roles: modalUserInfo.roles.sort(),
        start_date: modalUserInfo.start_date
          ? modalUserInfo.start_date
          : new Date(),
        end_date: modalUserInfo.end_date ? modalUserInfo.end_date : new Date(),
        created_by: modalUserInfo.created_by,
      };

      axios
        .put(`${ut_users_api_root}/users/${userInfo.netid}`, message, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": ut_users_api_key,
          },
        })
        .then((response) => {
          if (!response.data.error) {
            setSubmitStatus({
              loading: false,
              error: false,
              message: "The user was successfully updated!",
            });
            refreshUsers();
          } else {
            // Internal Error
            setSubmitStatus({
              loading: false,
              error: true,
              message:
                "There was an error updating the user: " + response.data.error,
            });
          }
        })
        .catch((error) => {
          setSubmitStatus({
            loading: false,
            error: true,
            message: "There was an error updating the user!",
          });
          console.log(error);
        });
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={`${modalAction} Message`}
      closeTimeoutMS={0}
    >
      <div className="modal-inner">
        <div className="modal-close-wrap">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={(event) => handleClose(event)}
          />
        </div>
        <div className="modal-heading">{modalAction} User</div>
        {apiMessage.ready ? (
          <div className="modal-body-container">
            <form onSubmit={(event) => handleSubmit(event)}>
              {userInfo.netid ? (
                <div className="form-group">
                  <div className="form-label">NetID:</div>
                  <input
                    type="text"
                    required
                    placeholder=""
                    autoComplete="off"
                    disabled
                    value={userInfo.netid}
                  />
                </div>
              ) : (
                <div className="form-group">
                  <div className="form-label">NetID:</div>
                  <input
                    type="text"
                    required
                    placeholder="Enter NetID"
                    autoComplete="off"
                    value={modalUserInfo.netid || ""}
                    onChange={(e) =>
                      setModalUserInfo({
                        ...modalUserInfo,
                        netid: e.target.value,
                      })
                    }
                  />
                </div>
              )}
              <div className="form-group">
                <div className="form-label">User Name:</div>
                <input
                  type="text"
                  required
                  placeholder="Enter name"
                  autoComplete="off"
                  value={modalUserInfo.name || ""}
                  onChange={(e) =>
                    setModalUserInfo({
                      ...modalUserInfo,
                      name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <div className="form-label">Start Date:</div>
                <DatePicker
                  dateFormat="MM/dd/yyyy"
                  selected={
                    modalUserInfo.start_date
                      ? new Date(modalUserInfo.start_date)
                      : new Date()
                  }
                  onChange={(date) =>
                    setModalUserInfo({
                      ...modalUserInfo,
                      start_date: date,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <div className="form-label">End Date:</div>
                <DatePicker
                  dateFormat="MM/dd/yyyy"
                  selected={
                    modalUserInfo.end_date
                      ? new Date(modalUserInfo.end_date)
                      : new Date()
                  }
                  onChange={(date) =>
                    setModalUserInfo({
                      ...modalUserInfo,
                      end_date: date,
                    })
                  }
                />
              </div>
              <div className="form-label">Roles:</div>
              {modalUserInfo.roles && modalUserInfo.roles.length > 0 ? (
                <div className="modal-role-group">
                  {modalUserInfo.roles.map((role) => (
                    <div
                      className="modal-role-wrap"
                      key={role}
                      onClick={(event) =>
                        setModalUserInfo({
                          ...modalUserInfo,
                          roles: modalUserInfo.roles.filter(
                            (removeRole) => role !== removeRole
                          ),
                        })
                      }
                    >
                      <div className="modal-role-label">{role}</div>
                      <div className="modal-role-remove">
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no-roles-msg">
                  User does not have any roles assigned.
                </div>
              )}
              <div className="form-group">
                <div className="form-label">Add Role:</div>
                <div className="custom-dd-wrap">
                  <select
                    value="init"
                    onChange={(e) =>
                      setModalUserInfo({
                        ...modalUserInfo,
                        roles: modalUserInfo.roles
                          ? [...modalUserInfo.roles, e.target.value]
                          : [e.target.value],
                      })
                    }
                  >
                    <option value="init" key="init" disabled>
                      Select a role.
                    </option>
                    {Roles.filter((role) => {
                      if (modalUserInfo.roles) {
                        if (!modalUserInfo.roles.includes(role.name))
                          return true;
                        else return false;
                      } else return true;
                    }).map((role) => (
                      <option value={role.name} key={role.name}>
                        {role.name} ({role.dispName})
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="btn-group two">
                <button type="submit" className="btn-sm">
                  Submit
                  {submitStatus.loading && (
                    <span>
                      <FontAwesomeIcon
                        icon={faCircleNotch}
                        className="btn-processing fa-spin"
                      />
                    </span>
                  )}
                </button>
                <button
                  className="btn-sm secondary"
                  onClick={(event) => handleClose(event)}
                >
                  Close
                </button>
              </div>
              {submitStatus.message !== "" && (
                <div
                  className={`submit-msg ${submitStatus.error ? "error" : ""}`}
                >
                  {submitStatus.message}
                </div>
              )}
            </form>
          </div>
        ) : (
          <Loader message={apiMessage} />
        )}
      </div>
    </Modal>
  );
};

export default UserManagementModal;
