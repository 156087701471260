import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import SortTable from "../../utils/SortTable";
import Config from "../../config/Config";
import Loader from "../../utils/Loader";
import FacultyModal from "./FacultyModal";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { convertDate } from "../../utils/DataTransformation";

const columns = [
  {
    Header: "Net ID",
    accessor: "NETID",
    sticky: "left",
  },
  {
    Header: "First Name",
    accessor: "FIRST_NAME",
  },
  {
    Header: "Last Name",
    accessor: "LAST_NAME",
  },
  {
    Header: "Faculty ID",
    accessor: "FACULTY_ID",
  },
  {
    Header: "Title",
    accessor: "TITLE_GROUP",
    type: "html",
  },
];

const defaultSorted = [{ id: "NETID", desc: false }];

const FacultyAdmin = () => {
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [titlesMessage, setTitlesMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [titleTypesMessage, setTitleTypesMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [faculty, setFaculty] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [facultyInfo, setFacultyInfo] = useState({});
  const [titles, setTitles] = useState([]);
  const [titleTypes, setTitleTypes] = useState([]);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    axios
      .get(fao_admin_api_root + "/faoadmin/titles", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setTitlesMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the faculty titles: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setTitles(response.data.data);
          setTitlesMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setTitlesMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no faculty titles.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setTitlesMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving faculty titles!",
        });
      });

    axios
      .get(fao_admin_api_root + "/faoadmin/titletypes", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setTitleTypesMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the faculty title types: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setTitleTypes(response.data.data);
          setTitleTypesMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setTitleTypesMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no faculty title types.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setTitleTypesMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving faculty title types!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key]);

  const handleRefreshFaculty = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    const transformFaculty = (faculty) => {
      const facultyMap = new Map();

      faculty.forEach((facultyMem) => {
        const curTitle = `<div class="faculty-title-cell-wrap"><div class="faculty-title-cell-name">${
          facultyMem.TITLE_NAME
        }</div><div class="faculty-title-cell-date">(${
          facultyMem.START_DATE
            ? convertDate(facultyMem.START_DATE)
            : "No Start Date"
        } - ${
          facultyMem.END_DATE ? convertDate(facultyMem.END_DATE) : "No End Date"
        })</div></div>`;

        // Append title to Title Group of map key
        if (facultyMap.has(facultyMem.NETID)) {
          let curTitleGroup = facultyMap.get(facultyMem.NETID).TITLE_GROUP;
          curTitleGroup += curTitle;

          facultyMap.set(facultyMem.NETID, {
            ...facultyMem,
            TITLE_GROUP: curTitleGroup,
          });
        }
        // Create new map key and initialize Title Group
        else
          facultyMap.set(facultyMem.NETID, {
            ...facultyMem,
            TITLE_GROUP: curTitle,
          });
      });

      return Array.from(facultyMap.values());
    };

    axios
      .get(fao_admin_api_root + "/faoadmin/faculty", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the faculty items: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setFaculty(transformFaculty(response.data.data));
          setAPIMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no faculty items.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAPIMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving faculty items!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key]);

  useEffect(() => {
    handleRefreshFaculty();
  }, [handleRefreshFaculty]);

  const handleEditClick = (event, row) => {
    setFacultyInfo(row.values);
    setModalOpen(true);
  };

  return (
    <div className="message-center-container">
      <div className="tool-title">FAO Faculty Admin</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The FAO Faculty Admin tool can be used to update the titles associated
          with faculty members.
        </div>
        <div className="tool-feature-list">
          <div className="tool-feature-label">Tool Features:</div>
          <ul>
            <li>Titles can be added, removed, and modified.</li>
            <li>
              The following fields can be modified for each title: Start Date,
              End Date, Current Flag.
            </li>
            <li>
              Titles can be managed via the{" "}
              <NavLink className="nav-link" to="/fao/titles">
                Titles Admin
              </NavLink>{" "}
              tool.
            </li>
            <li>
              Title Types can be managed via the{" "}
              <NavLink className="nav-link" to="/fao/titles/title-types">
                Title Types Admin
              </NavLink>{" "}
              tool.
            </li>
          </ul>
        </div>
      </div>
      <div className="filtering-container">
        <div className="filter-title">Filtering</div>
        <div className="filter-inputs-wrap one">
          <div className="input-icon-wrap">
            <div className="input-icon">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              value={filterText}
              type="text"
              placeholder="Search fields"
              autoComplete="off"
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
        </div>
        <button onClick={(event) => setFilterText("")}>Clear Filter</button>
      </div>
      <div className="table-container">
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={faculty.filter((facultyMember) => {
                return (
                  (facultyMember["NETID"] &&
                    facultyMember["NETID"]
                      .toString()
                      .toLowerCase()
                      .includes(filterText.toLowerCase())) ||
                  (facultyMember["FIRST_NAME"] &&
                    facultyMember["FIRST_NAME"]
                      .toString()
                      .toLowerCase()
                      .includes(filterText.toLowerCase())) ||
                  (facultyMember["LAST_NAME"] &&
                    facultyMember["LAST_NAME"]
                      .toString()
                      .toLowerCase()
                      .includes(filterText.toLowerCase())) ||
                  (facultyMember["FACULTY_ID"] &&
                    facultyMember["FACULTY_ID"].toString().includes(filterText))
                );
              })}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              paginationEnabled={true}
              editClick={handleEditClick}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
      <FacultyModal
        modalOpen={modalOpen}
        closeModal={(event) => setModalOpen(false)}
        facultyInfo={facultyInfo}
        refreshFaculty={handleRefreshFaculty}
        titles={titles}
        titlesMessage={titlesMessage}
        titleTypes={titleTypes}
        titleTypesMessage={titleTypesMessage}
      />
    </div>
  );
};

export default FacultyAdmin;
