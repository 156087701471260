import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { faTimes, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Config from "../../config/Config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "white",
    zIndex: "1",
    maxHeight: "90vh",
    overflow: "scroll",
    width: "400px",
    maxWidth: "calc(100% - 60px)",
  },
  overlay: {
    backgroundColor: "rgba(5, 7, 10, .8)",
    zIndex: "4001",
  },
};

const aToggleInputs = {
  1: ["division", "department"],
  2: ["division"],
  3: ["department"],
};

const TitleGroupsModal = ({
  modalOpen,
  modalAction,
  closeModal,
  titleGroupsInfo,
  refreshTitleGroups,
  titleGroupTypes,
}) => {
  const [modalTitleGroupsInfo, setModalTitleGroupsInfo] = useState({});
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    error: false,
    message: "",
  });
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const [divisions, setDivisions] = useState([]);
  const [divisionsMessage, setDivisionsMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [departments, setDepartments] = useState([]);
  const [deptsMessage, setDeptsMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });

  useEffect(() => {
    setModalTitleGroupsInfo(titleGroupsInfo);
  }, [titleGroupsInfo]);

  useEffect(() => {
    const transformDivs = (divs) =>
      divs.map((div) => {
        return {
          name: div.DIVISION_ID,
          dispName: `${div.DV_NAME} (${div.DIVISION_ID})`,
        };
      });

    axios
      .get(fao_admin_api_root + "/faoadmin/divisions", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setDivisionsMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the divisions: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setDivisions(transformDivs(response.data.data));
          setDivisionsMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setDivisionsMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no divisions.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setDivisionsMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving divisions!",
        });
      });

    const transformDepts = (depts) =>
      depts.map((dept) => {
        return {
          name: dept.DEPT_ID,
          dispName: `${dept.DEPT_NAME} (${dept.DEPT_ID})`,
        };
      });

    axios
      .get(fao_admin_api_root + "/faoadmin/departments", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setDeptsMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the departments: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setDepartments(transformDepts(response.data.data));
          setDeptsMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setDeptsMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no departments.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setDeptsMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving departments!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key]);

  const handleClose = (event) => {
    event.preventDefault();
    setSubmitStatus({
      loading: false,
      error: false,
      message: "",
    });
    closeModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitStatus({ loading: true });

    if (!modalTitleGroupsInfo.GROUP_ID) {
      setSubmitStatus({
        loading: false,
        error: true,
        message: "Title Group Type is a required field!",
      });
    } else {
      if (modalAction === "Add") {
        let message = {
          faculty_id: modalTitleGroupsInfo.FACULTY_ID,
          division_id: modalTitleGroupsInfo.DIVISION_ID,
          dept_id: modalTitleGroupsInfo.DEPT_ID,
          start_date: modalTitleGroupsInfo.START_DATE || new Date(),
          end_date: modalTitleGroupsInfo.END_DATE,
          admin_title: modalTitleGroupsInfo.ADMIN_TITLE,
        };

        axios
          .post(
            `${fao_admin_api_root}/faoadmin/titles/groups/${modalTitleGroupsInfo.GROUP_ID}`,
            message,
            {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": fao_admin_api_key,
              },
            }
          )
          .then((response) => {
            if (!response.data.error) {
              setSubmitStatus({
                loading: false,
                error: false,
                message: "The title group was successfully added!",
              });
              refreshTitleGroups();
            } else {
              // Internal Error
              setSubmitStatus({
                loading: false,
                error: true,
                message:
                  "There was an error adding the title group: " +
                  response.data.error,
              });
            }
          })
          .catch((error) => {
            setSubmitStatus({
              loading: false,
              error: true,
              message: "There was an error adding the title group!",
            });
            console.log(error);
          });
      } else if (modalAction === "Edit") {
        let message = {
          id: modalTitleGroupsInfo.ID,
          faculty_id: modalTitleGroupsInfo.FACULTY_ID,
          division_id: modalTitleGroupsInfo.DIVISION_ID,
          dept_id: modalTitleGroupsInfo.DEPT_ID,
          start_date: modalTitleGroupsInfo.START_DATE || new Date(),
          end_date: modalTitleGroupsInfo.END_DATE,
          admin_title: modalTitleGroupsInfo.ADMIN_TITLE,
        };

        axios
          .put(
            `${fao_admin_api_root}/faoadmin/titles/groups/${modalTitleGroupsInfo.GROUP_ID}`,
            message,
            {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": fao_admin_api_key,
              },
            }
          )
          .then((response) => {
            if (!response.data.error) {
              setSubmitStatus({
                loading: false,
                error: false,
                message: "The title group was successfully updated!",
              });
              refreshTitleGroups();
            } else {
              // Internal Error
              setSubmitStatus({
                loading: false,
                error: true,
                message:
                  "There was an error updating the title group: " +
                  response.data.error,
              });
            }
          })
          .catch((error) => {
            setSubmitStatus({
              loading: false,
              error: true,
              message: "There was an error updating the title group!",
            });
            console.log(error);
          });
      }
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel={`${modalAction} Title Group`}
      closeTimeoutMS={0}
    >
      <div className="modal-inner">
        <div className="modal-close-wrap">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={(event) => handleClose(event)}
          />
        </div>
        <div className="modal-heading">{modalAction} Title Group</div>
        <div className="modal-body-container">
          <form onSubmit={(event) => handleSubmit(event)}>
            {titleGroupsInfo.FACULTY_ID ? (
              <div className="form-group">
                <div className="form-label">Faculty ID:</div>
                <input
                  type="text"
                  required
                  placeholder=""
                  autoComplete="off"
                  disabled
                  value={titleGroupsInfo.FACULTY_ID}
                />
              </div>
            ) : (
              <div className="form-group">
                <div className="form-label">Faculty ID:</div>
                <input
                  type="text"
                  required
                  placeholder=""
                  autoComplete="off"
                  value={modalTitleGroupsInfo.FACULTY_ID || ""}
                  onChange={(e) =>
                    setModalTitleGroupsInfo({
                      ...modalTitleGroupsInfo,
                      FACULTY_ID: e.target.value,
                    })
                  }
                />
              </div>
            )}
            {modalTitleGroupsInfo.NAME && (
              <div className="form-group">
                <div className="form-label">Name:</div>
                <input
                  type="text"
                  required
                  placeholder=""
                  autoComplete="off"
                  disabled
                  value={modalTitleGroupsInfo.NAME}
                />
              </div>
            )}
            {titleGroupTypes && (
              <div className="form-group">
                <div className="form-label">Title Group Type:</div>
                <div className="custom-dd-wrap">
                  <select
                    value={modalTitleGroupsInfo.GROUP_ID || "init"}
                    required
                    onChange={(e) =>
                      setModalTitleGroupsInfo({
                        ...modalTitleGroupsInfo,
                        GROUP_ID: e.target.value,
                      })
                    }
                  >
                    <option value="init" disabled>
                      Select group ID.
                    </option>
                    {titleGroupTypes.map((titleGroupType) => (
                      <option
                        value={titleGroupType.name}
                        key={titleGroupType.name}
                      >
                        {titleGroupType.dispName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div className="form-group">
              <div className="form-label">Start Date:</div>
              <DatePicker
                selected={
                  modalTitleGroupsInfo.START_DATE
                    ? new Date(modalTitleGroupsInfo.START_DATE)
                    : new Date()
                }
                onChange={(date) =>
                  setModalTitleGroupsInfo({
                    ...modalTitleGroupsInfo,
                    START_DATE: date,
                  })
                }
              />
            </div>
            <div className="form-group">
              <div className="form-label">End Date:</div>
              {!modalTitleGroupsInfo.END_DATE ? (
                <div>
                  An end date is not specified for this title group.{" "}
                  <span
                    className="text-add-end-date"
                    onClick={(event) =>
                      setModalTitleGroupsInfo({
                        ...modalTitleGroupsInfo,
                        END_DATE: new Date(),
                      })
                    }
                  >
                    Add?
                  </span>
                </div>
              ) : (
                <DatePicker
                  selected={
                    modalTitleGroupsInfo.END_DATE
                      ? new Date(modalTitleGroupsInfo.END_DATE)
                      : new Date()
                  }
                  onChange={(date) =>
                    setModalTitleGroupsInfo({
                      ...modalTitleGroupsInfo,
                      END_DATE: date,
                    })
                  }
                />
              )}
            </div>
            <div className="form-group">
              <div className="form-label">Admin Title:</div>
              <input
                type="text"
                required
                placeholder=""
                autoComplete="off"
                value={modalTitleGroupsInfo.ADMIN_TITLE || ""}
                onChange={(e) =>
                  setModalTitleGroupsInfo({
                    ...modalTitleGroupsInfo,
                    ADMIN_TITLE: e.target.value,
                  })
                }
              />
            </div>
            {modalAction === "Edit" && (
              <div className="form-group">
                <div className="form-label">Area:</div>
                <input
                  type="text"
                  required
                  placeholder=""
                  autoComplete="off"
                  disabled
                  value={modalTitleGroupsInfo.AREA || ""}
                />
              </div>
            )}
            {modalAction === "Add" &&
              aToggleInputs[modalTitleGroupsInfo.GROUP_ID] &&
              aToggleInputs[modalTitleGroupsInfo.GROUP_ID].includes(
                "division"
              ) && (
                <div className="form-group">
                  <div className="form-label">Division:</div>
                  <div className="custom-dd-wrap">
                    <select
                      value={modalTitleGroupsInfo.DIVISION_ID || "init"}
                      onChange={(e) =>
                        setModalTitleGroupsInfo({
                          ...modalTitleGroupsInfo,
                          DIVISION_ID: e.target.value,
                        })
                      }
                    >
                      {divisionsMessage.ready ? (
                        <>
                          <option value="init" disabled>
                            Select a division.
                          </option>
                          {divisions.map((division) => (
                            <option value={division.name} key={division.name}>
                              {division.dispName}
                            </option>
                          ))}
                        </>
                      ) : (
                        <option value="init" disabled>
                          Loading...
                        </option>
                      )}
                    </select>
                  </div>
                </div>
              )}
            {modalAction === "Add" &&
              aToggleInputs[modalTitleGroupsInfo.GROUP_ID] &&
              aToggleInputs[modalTitleGroupsInfo.GROUP_ID].includes(
                "department"
              ) && (
                <div className="form-group">
                  <div className="form-label">Department:</div>
                  <div className="custom-dd-wrap">
                    <select
                      value={modalTitleGroupsInfo.DEPT_ID || "init"}
                      onChange={(e) =>
                        setModalTitleGroupsInfo({
                          ...modalTitleGroupsInfo,
                          DEPT_ID: e.target.value,
                        })
                      }
                    >
                      {deptsMessage.ready ? (
                        <>
                          <option value="init" disabled>
                            Select a department.
                          </option>
                          {departments.map((department) => (
                            <option
                              value={department.name}
                              key={department.name}
                            >
                              {department.dispName}
                            </option>
                          ))}
                        </>
                      ) : (
                        <option value="init" disabled>
                          Loading...
                        </option>
                      )}
                    </select>
                  </div>
                </div>
              )}
            <div className="btn-group two">
              <button type="submit" className="btn-sm">
                Submit
                {submitStatus.loading && (
                  <span>
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      className="btn-processing fa-spin"
                    />
                  </span>
                )}
              </button>
              <button
                type="button"
                className="btn-sm secondary"
                onClick={(event) => handleClose(event)}
              >
                Close
              </button>
            </div>
            {submitStatus.message !== "" && (
              <div
                className={`submit-msg ${submitStatus.error ? "error" : ""}`}
              >
                {submitStatus.message}
              </div>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default TitleGroupsModal;
