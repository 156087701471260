import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { faTimes, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Config from "../../config/Config";
import { useUserContext } from "../../context/UserContext";
import FilterSelect from "../../utils/FilterSelect";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "white",
    zIndex: "1",
    maxHeight: "90vh",
    overflow: "scroll",
    width: "400px",
    maxWidth: "calc(100% - 60px)",
  },
  overlay: {
    backgroundColor: "rgba(5, 7, 10, .8)",
    zIndex: "4001",
  },
};

const DivisionsModal = ({
  modalOpen,
  modalAction,
  closeModal,
  divisionsInfo,
  refreshDivisions,
  buildings,
  buildingsMessage,
}) => {
  const [modalDivisionsInfo, setModalDivisionsInfo] = useState({});
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    error: false,
    message: "",
  });
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const context = useUserContext();

  let { netid } = context.userUT || {};

  useEffect(() => {
    if (divisionsInfo)
      setModalDivisionsInfo({
        ...divisionsInfo,
        buildingLabel: `${divisionsInfo.DV_LOCATION_ID} (${divisionsInfo.STREET_ADDRESS})`,
      });
  }, [divisionsInfo]);

  const handleClose = (event) => {
    event.preventDefault();
    setModalDivisionsInfo({});
    setSubmitStatus({
      loading: false,
      error: false,
      message: "",
    });
    closeModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitStatus({ loading: true });

    let message = {
      modify_user: netid,
      dv_url: modalDivisionsInfo.DV_URL,
      dv_room_number: modalDivisionsInfo.DV_ROOM_NUMBER,
      dv_phone: modalDivisionsInfo.DV_PHONE,
      dv_location_id: modalDivisionsInfo.DV_LOCATION_ID,
      search_name: modalDivisionsInfo.SEARCH_NAME,
      label_name: modalDivisionsInfo.LABEL_NAME,
      dept_search_name: modalDivisionsInfo.DEPT_SEARCH_NAME,
    };

    axios
      .put(
        `${fao_admin_api_root}/faoadmin/divisions/${divisionsInfo.DIVISION_ID}`,
        message,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": fao_admin_api_key,
          },
        }
      )
      .then((response) => {
        if (!response.data.error) {
          setSubmitStatus({
            loading: false,
            error: false,
            message: "The division was successfully updated!",
          });
          refreshDivisions();
        } else {
          // Internal Error
          setSubmitStatus({
            loading: false,
            error: true,
            message:
              "There was an error updating the division: " +
              response.data.error,
          });
        }
      })
      .catch((error) => {
        setSubmitStatus({
          loading: false,
          error: true,
          message: "There was an error updating the division!",
        });
        console.log(error);
      });
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Edit Division"
      closeTimeoutMS={0}
    >
      <div className="modal-inner">
        <div className="modal-close-wrap">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={(event) => handleClose(event)}
          />
        </div>
        <div className="modal-heading">{modalAction} Division</div>
        <div className="modal-body-container">
          <form onSubmit={(event) => handleSubmit(event)}>
            <div className="form-group">
              <div className="form-label">Division ID:</div>
              <input
                type="text"
                required
                placeholder=""
                autoComplete="off"
                disabled
                value={modalDivisionsInfo.DIVISION_ID}
              />
            </div>
            <div className="form-group">
              <div className="form-label">Division URL:</div>
              <input
                type="text"
                placeholder=""
                autoComplete="off"
                value={modalDivisionsInfo.DV_URL || ""}
                onChange={(e) =>
                  setModalDivisionsInfo({
                    ...modalDivisionsInfo,
                    DV_URL: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <div className="form-label">Division Room Number:</div>
              <input
                type="text"
                placeholder=""
                autoComplete="off"
                value={modalDivisionsInfo.DV_ROOM_NUMBER || ""}
                onChange={(e) =>
                  setModalDivisionsInfo({
                    ...modalDivisionsInfo,
                    DV_ROOM_NUMBER: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <div className="form-label">Division Phone:</div>
              <input
                type="text"
                placeholder=""
                autoComplete="off"
                value={modalDivisionsInfo.DV_PHONE || ""}
                onChange={(e) =>
                  setModalDivisionsInfo({
                    ...modalDivisionsInfo,
                    DV_PHONE: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <div className="form-label">Division Location ID (Building):</div>
              {buildingsMessage.ready ? (
                <FilterSelect
                  data={buildings}
                  dataType="buildings"
                  value={
                    divisionsInfo.DV_LOCATION_ID
                      ? {
                          label: modalDivisionsInfo.buildingLabel,
                          value: modalDivisionsInfo.DV_LOCATION_ID,
                        }
                      : undefined
                  }
                  onChange={(e) => {
                    if (e) {
                      setModalDivisionsInfo({
                        ...modalDivisionsInfo,
                        buildingLabel: e.label,
                        DV_LOCATION_ID: e.value,
                      });
                    }
                  }}
                />
              ) : (
                <div>{buildingsMessage.messageText}</div>
              )}
            </div>
            <div className="form-group">
              <div className="form-label">Search Name:</div>
              <input
                type="text"
                placeholder=""
                autoComplete="off"
                value={modalDivisionsInfo.SEARCH_NAME || ""}
                onChange={(e) =>
                  setModalDivisionsInfo({
                    ...modalDivisionsInfo,
                    SEARCH_NAME: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <div className="form-label">Label Name:</div>
              <input
                type="text"
                placeholder=""
                autoComplete="off"
                value={modalDivisionsInfo.LABEL_NAME || ""}
                onChange={(e) =>
                  setModalDivisionsInfo({
                    ...modalDivisionsInfo,
                    LABEL_NAME: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <div className="form-label">Department Search Name:</div>
              <input
                type="text"
                placeholder=""
                autoComplete="off"
                value={modalDivisionsInfo.DEPT_SEARCH_NAME || ""}
                onChange={(e) =>
                  setModalDivisionsInfo({
                    ...modalDivisionsInfo,
                    DEPT_SEARCH_NAME: e.target.value,
                  })
                }
              />
            </div>
            <div className="btn-group two">
              <button type="submit" className="btn-sm">
                Submit
                {submitStatus.loading && (
                  <span>
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      className="btn-processing fa-spin"
                    />
                  </span>
                )}
              </button>
              <button
                type="button"
                className="btn-sm secondary"
                onClick={(event) => handleClose(event)}
              >
                Close
              </button>
            </div>
            {submitStatus.message !== "" && (
              <div
                className={`submit-msg ${submitStatus.error ? "error" : ""}`}
              >
                {submitStatus.message}
              </div>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default DivisionsModal;
