import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import SortTable from "../../utils/SortTable";
import Config from "../../config/Config";
import Loader from "../../utils/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import FacultyNoteTypesModal from "./FacultyNoteTypesModal";

const columns = [
  {
    Header: "Note Type ID",
    accessor: "NOTE_TYPE_ID",
    sticky: "left",
  },
  {
    Header: "Note Type",
    accessor: "NOTE_TYPE",
  },
  {
    Header: "Active Flag",
    accessor: "ACTIVE_FLAG",
  },
  {
    Header: "Count",
    accessor: "COUNT",
  },
];

const defaultSorted = [{ id: "NOTE_TYPE_ID", desc: false }];

const FacultyNoteTypesAdmin = () => {
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [noteTypes, setNoteTypes] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(false);
  const [noteTypeInfo, setNoteTypeInfo] = useState({});
  const [filterText, setFilterText] = useState("");

  const handleRefreshNoteTypes = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    axios
      .get(fao_admin_api_root + "/faoadmin/faculty/notetypes", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the faculty note types: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setNoteTypes(response.data.data);
          setAPIMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no faculty note types.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAPIMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving faculty note types!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key]);

  useEffect(() => {
    handleRefreshNoteTypes();
  }, [handleRefreshNoteTypes]);

  const handleAddClick = (event) => {
    setNoteTypeInfo({});
    setModalAction("Add");
    setModalOpen(true);
  };

  const handleEditClick = (event, row) => {
    setNoteTypeInfo(row.values);
    setModalAction("Edit");
    setModalOpen(true);
  };

  const filterData = (data) => {
    return data.filter((dataElem) => {
      var bFound = false;
      columns.forEach((column) => {
        if (
          dataElem[column.accessor] &&
          dataElem[column.accessor]
            .toString()
            .toLowerCase()
            .includes(filterText.toLowerCase())
        )
          bFound = true;
      });
      return bFound;
    });
  };

  return (
    <div className="faculty-admin-container">
      <div className="tool-title">Faculty Note Types Admin</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The Faculty Note Types Admin tool can be used to perform various tasks
          on faculty note types.
        </div>
        <div className="tool-feature-list">
          <div className="tool-feature-label">Tool Features:</div>
          <ul>
            <li>Professorships note types can be added and modified.</li>
            <li>
              The following fields can be modified for each professorship note
              type: active_flag, note_type, note_type_id.
            </li>
          </ul>
        </div>
      </div>
      <div className="filtering-container">
        <div className="filter-title">Filtering</div>
        <div className="filter-inputs-wrap one">
          <div className="input-icon-wrap">
            <div className="input-icon">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              value={filterText}
              type="text"
              placeholder="Search fields"
              autoComplete="off"
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="table-container">
        <div className="btn-add-message-wrap">
          <button onClick={(event) => handleAddClick()}>
            <FontAwesomeIcon icon={faPlus} className="btn-add-message" />
            Add Note Type
          </button>
        </div>
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={filterData(noteTypes)}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              editClick={handleEditClick}
              paginationEnabled={true}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
      <FacultyNoteTypesModal
        modalOpen={modalOpen}
        modalAction={modalAction}
        closeModal={(event) => setModalOpen(false)}
        noteTypeInfo={noteTypeInfo}
        refreshNoteTypes={handleRefreshNoteTypes}
      />
    </div>
  );
};

export default FacultyNoteTypesAdmin;
