import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { faTimes, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Config from "../config/Config";
import axios from "axios";
import { useUserContext } from "../context/UserContext";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "white",
    zIndex: "1",
    maxHeight: "100vh",
    overflow: "scroll",
    width: "400px",
    maxWidth: "calc(100% - 60px)",
  },
  overlay: {
    backgroundColor: "rgba(5, 7, 10, .8)",
    zIndex: "4001",
  },
};

const DBManifestManagementModal = ({
  modalOpen,
  closeModal,
  action,
  scriptInfo,
  refreshScripts,
}) => {
  const [modalScriptInfo, setModalScriptInfo] = useState({});
  let { db_manifest_api_root, db_manifest_api_key } = Config;
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    error: false,
    message: "",
  });
  const context = useUserContext();
  let { userUT } = context;

  useEffect(() => {
    if (scriptInfo) setModalScriptInfo(scriptInfo);
  }, [scriptInfo]);

  const handleClose = (event) => {
    event.preventDefault();
    setSubmitStatus({
      loading: false,
      error: false,
      message: "",
    });
    closeModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setSubmitStatus({ loading: true });

    if (action === "Edit") {
      let { enabled, scriptName, db_schema, runOnce } = modalScriptInfo;
      let userModified = userUT.netid;

      let messageBody = {
        enabled,
        scriptName,
        db_schema,
        runOnce,
        userModified,
      };

      axios
        .put(
          db_manifest_api_root + `/script/${modalScriptInfo.id}`,
          messageBody,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": db_manifest_api_key,
            },
          }
        )
        .then((response) => {
          setSubmitStatus({
            loading: false,
            error: false,
            message: "The db script was successfully updated!",
          });
          refreshScripts();
        })
        .catch((error) => {
          console.log(error);
          setSubmitStatus({
            loading: false,
            error: true,
            message: "There was an error saving the db script!",
          });
        });
    } else if (action === "Add") {
      let { enabled, scriptName, db_schema, runOnce } = modalScriptInfo;
      let userAdded = userUT.netid;

      let messageBody = {
        enabled,
        scriptName,
        db_schema,
        runOnce,
        userAdded,
      };

      axios
        .post(db_manifest_api_root + `/script`, messageBody, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": db_manifest_api_key,
          },
        })
        .then((response) => {
          setSubmitStatus({
            loading: false,
            error: false,
            message: "The db script was successfully added!",
          });
          refreshScripts();
        })
        .catch((error) => {
          console.log(error);
          setSubmitStatus({
            loading: false,
            error: true,
            message: "There was an error adding the db script!",
          });
        });
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={`${action} DB Script`}
      closeTimeoutMS={0}
    >
      <div className="modal-inner">
        <div className="modal-close-wrap">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={(event) => handleClose(event)}
          />
        </div>
        <div className="modal-heading">{action} DB Script</div>
        <div className="modal-body-container">
          <form onSubmit={(event) => handleSubmit(event)}>
            <div className="form-group">
              <div className="form-label">Script Name:</div>
              <input
                type="text"
                required
                placeholder=""
                autoComplete="off"
                value={modalScriptInfo.scriptName}
                onChange={(e) =>
                  setModalScriptInfo({
                    ...modalScriptInfo,
                    scriptName: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <div className="form-label">Enabled:</div>
              <div className="custom-dd-wrap">
                <select
                  value={modalScriptInfo.enabled}
                  onChange={(e) =>
                    setModalScriptInfo({
                      ...modalScriptInfo,
                      enabled: e.target.value,
                    })
                  }
                >
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <div className="form-label">Run Once:</div>
              <div className="custom-dd-wrap">
                <select
                  value={modalScriptInfo.runOnce}
                  onChange={(e) =>
                    setModalScriptInfo({
                      ...modalScriptInfo,
                      runOnce: e.target.value,
                    })
                  }
                >
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <div className="form-label">DB Schema:</div>
              <input
                type="text"
                required
                placeholder=""
                autoComplete="off"
                value={modalScriptInfo.db_schema}
                onChange={(e) =>
                  setModalScriptInfo({
                    ...modalScriptInfo,
                    db_schema: e.target.value,
                  })
                }
              />
            </div>
            <div className="btn-group two">
              <button className="btn-sm">
                Submit
                {submitStatus.loading && (
                  <span>
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      className="btn-processing fa-spin"
                    />
                  </span>
                )}
              </button>
              <button
                className="btn-sm secondary"
                onClick={(event) => handleClose(event)}
              >
                Close
              </button>
            </div>
            {submitStatus.message !== "" && (
              <div
                className={`submit-msg ${submitStatus.error ? "error" : ""}`}
              >
                {submitStatus.message}
              </div>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default DBManifestManagementModal;
