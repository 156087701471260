import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import SortTable from "../../utils/SortTable";
import Config from "../../config/Config";
import Loader from "../../utils/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const columns = [
  {
    Header: "Location ID",
    accessor: "LOCATION_ID",
    sticky: "left",
  },
  {
    Header: "Campus Name",
    accessor: "CAMPUS_NAME_CSZ",
  },
  {
    Header: "Location",
    accessor: "LOCATION",
  },
  {
    Header: "Building",
    accessor: "BUILDING",
  },
  {
    Header: "Room Suite Box",
    accessor: "ROOM_SUITE_BOX",
  },
  {
    Header: "Street Address",
    accessor: "STREET_ADDRESS",
  },
  {
    Header: "City",
    accessor: "CITY",
  },
  {
    Header: "State",
    accessor: "STATE",
  },
  {
    Header: "Zip",
    accessor: "ZIP",
  },
];

const defaultSorted = [{ id: "CAMPUS_NAME_CSZ", desc: false }];

const BuildingsAdmin = () => {
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [buildings, setBuildings] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [dataChanged, setDataChanged] = useState(false); // TODO: refactor, this is hacky

  const handleRefreshBuildings = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    axios
      .get(fao_admin_api_root + "/faoadmin/buildings", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the buildings: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setBuildings(response.data.data);
          setAPIMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no buildings.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAPIMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving buildings!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key]);

  useEffect(() => {
    handleRefreshBuildings();
  }, [handleRefreshBuildings]);

  const filterData = (data) => {
    return data.filter((dataElem) => {
      var bFound = false;
      columns.forEach((column) => {
        if (
          dataElem[column.accessor] &&
          dataElem[column.accessor]
            .toString()
            .toLowerCase()
            .includes(filterText.toLowerCase())
        )
          bFound = true;
      });
      return bFound;
    });
  };

  return (
    <div className="message-center-container">
      <div className="tool-title">FAO Buildings Admin</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The FAO Buildings Admin tool can be used to browse buildings that are
          associated with Faculty Affairs.
        </div>
      </div>
      <div className="filtering-container">
        <div className="filter-title">Filtering</div>
        <div className="filter-inputs-wrap one">
          <div className="input-icon-wrap">
            <div className="input-icon">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              value={filterText}
              type="text"
              placeholder="Search fields"
              autoComplete="off"
              onChange={(e) => {
                setDataChanged(true);
                setFilterText(e.target.value);
              }}
              onKeyUp={(e) => setDataChanged(false)}
            />
          </div>
        </div>
        <button onClick={(event) => setFilterText("")}>Clear Filter</button>
      </div>
      <div className="table-container">
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={filterData(buildings)}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              paginationEnabled={true}
              dataChanged={dataChanged}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BuildingsAdmin;
