export const convertDate = (dateString) => {
  var date = new Date(dateString);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;
  if (year < 1000) year = "20" + year;

  let formattedDate = year + "-" + month + "-" + day;

  return formattedDate;
};
