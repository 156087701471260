import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import SortTable from "../../utils/SortTable";
import Config from "../../config/Config";
import Loader from "../../utils/Loader";
import DepartmentsModal from "./DepartmentsModal";

const columns = [
  {
    Header: "Department",
    accessor: "DEPT_COMBO",
    type: "html",
    sticky: "left",
  },
  {
    Header: "Department ID",
    accessor: "HIDE_DEPT_ID",
  },
  {
    Header: "Department Name",
    accessor: "HIDE_DEPT_NAME",
  },
  {
    Header: "Department URL",
    accessor: "DV_URL",
  },
  {
    Header: "Department Room Number",
    accessor: "DV_ROOM_NUMBER",
  },
  {
    Header: "Department Phone",
    accessor: "DV_PHONE",
  },
  {
    Header: "Department Location ID",
    accessor: "DV_LOCATION_ID",
  },
  {
    Header: "Building",
    accessor: "BUILDING",
  },
  {
    Header: "Street Address",
    accessor: "STREET_ADDRESS",
  },
  {
    Header: "City",
    accessor: "CITY",
  },
  {
    Header: "State",
    accessor: "STATE",
  },
  {
    Header: "Zip",
    accessor: "ZIP",
  },
  {
    Header: "Campus Name",
    accessor: "CAMPUS_NAME_CSZ",
  },
  {
    Header: "Department Abbreviation",
    accessor: "DV_ABBR",
  },
  {
    Header: "Search Name",
    accessor: "SEARCH_NAME",
  },
  {
    Header: "Label Name",
    accessor: "LABEL_NAME",
  },
  {
    Header: "Department Search Name",
    accessor: "DEPT_SEARCH_NAME",
  },
];

const defaultSorted = [{ id: "DEPT_COMBO", desc: false }];

const DepartmentsAdmin = () => {
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [departments, setDepartments] = useState([]);
  const [departmentsInfo, setDepartmentsInfo] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(false);

  const handleRefreshDepartments = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    const transformDepts = (depts) =>
      depts.map((dept) => {
        return {
          ...dept,
          HIDE_DEPT_ID: dept.DEPT_ID,
          HIDE_DEPT_NAME: dept.DEPT_NAME,
          DEPT_COMBO: `<span style="white-space: nowrap;">${dept.DEPT_NAME} (${dept.DEPT_ID})</span>`,
        };
      });

    axios
      .get(fao_admin_api_root + "/faoadmin/departments", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the departments: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setDepartments(transformDepts(response.data.data));
          setAPIMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no departments.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAPIMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving departments!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key]);

  useEffect(() => {
    handleRefreshDepartments();
  }, [handleRefreshDepartments]);

  const handleEditClick = (event, row) => {
    setDepartmentsInfo(row.values);
    setModalAction("Edit");
    setModalOpen(true);
  };

  return (
    <div className="message-center-container">
      <div className="tool-title">FAO Departments Admin</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The FAO Buildings Admin tool can be used to manage departments that
          are associated with Faculty Affairs.
        </div>
        <div className="tool-feature-list">
          <div className="tool-feature-label">Tool Features:</div>
          <ul>
            <li>
              The following fields can be modified for each department:
              Department URL, Department Room Number, Department Phone,
              Department Location ID, Search Name, Label Name, Department Search
              Name.
            </li>
          </ul>
        </div>
      </div>
      <div className="table-container">
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={departments}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              paginationEnabled={true}
              editClick={handleEditClick}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
      <DepartmentsModal
        modalOpen={modalOpen}
        modalAction={modalAction}
        closeModal={(event) => setModalOpen(false)}
        departmentsInfo={departmentsInfo}
        refreshDepartments={handleRefreshDepartments}
      />
    </div>
  );
};

export default DepartmentsAdmin;
