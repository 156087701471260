const local = {
  rbac_enabled: true,
  mc_api_root: "https://mc-dev.api.fsm.northwestern.edu",
  mc_api_key: "8EGPduKJSn4aYZ3QJ8tly4LkQGL1LfKj8D9bf6Br",
  portal_api_root: "https://pt-dev.api.fsm.northwestern.edu",
  portal_api_key: "0oyhzcbe2y6tttdlAtFO33wojzCpldKQ67J855vM",
  db_manifest_api_root: "https://db-manifest-dev.api.fsm.northwestern.edu",
  db_manifest_api_key: "DXK6CEE8Gc3klZmOUrqRo8MpL9QoxKcG61ZpRRaU",
  fao_admin_api_root: "https://faoadmin-dev.api.fsm.northwestern.edu",
  fao_admin_api_key: "01tTgR5chx4KkX1948kRLBLR650d8wu22wQZJVD6",
  ut_users_api_root: "https://ut-users-dev.api.fsm.northwestern.edu",
  ut_users_api_key: "65uAfT5jv4YgqSgT9MCx3fmqpttkucq6DSgXUZJ1",
  msalConfig: {
    auth: {
      clientId: "cf492910-7b5f-47ef-9d35-62488252ad45",
      redirectUri: "http://localhost:3000",
      authority: "https://login.microsoftonline.com/nuwildcat.onmicrosoft.com",
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri: "http://localhost:3000",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  loginRequest: {
    scopes: ["User.Read"],
    extraQueryParameters: { domain_hint: "northwestern.edu" },
  },
  graphConfig: {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  },
};

const dev = {
  rbac_enabled: true,
  mc_api_root: "https://mc-dev.api.fsm.northwestern.edu",
  mc_api_key: "8EGPduKJSn4aYZ3QJ8tly4LkQGL1LfKj8D9bf6Br",
  portal_api_root: "https://pt-dev.api.fsm.northwestern.edu",
  portal_api_key: "0oyhzcbe2y6tttdlAtFO33wojzCpldKQ67J855vM",
  db_manifest_api_root: "https://db-manifest-dev.api.fsm.northwestern.edu",
  db_manifest_api_key: "DXK6CEE8Gc3klZmOUrqRo8MpL9QoxKcG61ZpRRaU",
  fao_admin_api_root: "https://faoadmin-dev.api.fsm.northwestern.edu",
  fao_admin_api_key: "01tTgR5chx4KkX1948kRLBLR650d8wu22wQZJVD6",
  ut_users_api_root: "https://ut-users-dev.api.fsm.northwestern.edu",
  ut_users_api_key: "65uAfT5jv4YgqSgT9MCx3fmqpttkucq6DSgXUZJ1",
  msalConfig: {
    auth: {
      clientId: "b56d1dd9-7bff-4c5f-b99b-8f4870add9d4",
      redirectUri: "https://ut-dev.fsm.northwestern.edu",
      authority: "https://login.microsoftonline.com/nuwildcat.onmicrosoft.com",
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri: "https://ut-dev.fsm.northwestern.edu",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  loginRequest: {
    scopes: ["User.Read"],
    extraQueryParameters: { domain_hint: "northwestern.edu" },
  },
  graphConfig: {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  },
};

const uat = {
  rbac_enabled: true,
  mc_api_root: "https://mc-uat.api.fsm.northwestern.edu",
  mc_api_key: "9C1o6fE9bB7l2g0wdTQt4ZcOlt48ovWaGVNWpFR1",
  portal_api_root: "https://pt-uat.api.fsm.northwestern.edu",
  portal_api_key: "iFvThq5d7i2i4kWPkIRZl4j5px31o2uR8uewEDwD",
  db_manifest_api_root: "https://db-manifest-uat.api.fsm.northwestern.edu",
  db_manifest_api_key: "Rqol1wpGBi7tIXcj2a7l76Wp5dxmCvya1Q1kTUbo",
  fao_admin_api_root: "https://faoadmin-uat.api.fsm.northwestern.edu",
  fao_admin_api_key: "ocAfQ2fZ0h1nbrVrdx0jB9KZuyXwtgVE856vHG8H",
  ut_users_api_root: "https://ut-users-uat.api.fsm.northwestern.edu",
  ut_users_api_key: "SKsfWK2rxsqQUpbzuetma6f9yNwtsOf7MSTtKME9",
  msalConfig: {
    auth: {
      clientId: "2b1d1797-a1c7-4c61-80e0-5dc524a3361c",
      redirectUri: "https://ut-uat.fsm.northwestern.edu",
      authority: "https://login.microsoftonline.com/nuwildcat.onmicrosoft.com",
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri: "https://ut-uat.fsm.northwestern.edu",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  loginRequest: {
    scopes: ["User.Read"],
    extraQueryParameters: { domain_hint: "northwestern.edu" },
  },
  graphConfig: {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  },
};

const prod = {
  rbac_enabled: true,
  mc_api_root: "https://mc.api.fsm.northwestern.edu",
  mc_api_key: "jB4ayvX0y874agP6b6EnVnYYVxAt9aX52cDubku6",
  portal_api_root: "https://pt.api.fsm.northwestern.edu",
  portal_api_key: "jZorAg9YqN576ua7znr4M19V8F0Jyxmqvs1dj2t2",
  db_manifest_api_root: "https://db-manifest.api.fsm.northwestern.edu",
  db_manifest_api_key: "nekRt2gkOK6VUOhu1zmsy7pxnurTaIiT72hUlmcq",
  fao_admin_api_root: "https://faoadmin.api.fsm.northwestern.edu",
  fao_admin_api_key: "iPuQ8RRRBGia4SBAWQKi5ZSyrE5MQ234SE9RdIya",
  ut_users_api_root: "https://ut-users.api.fsm.northwestern.edu",
  ut_users_api_key: "1Xp3K0091z1DI02rqtlNfWvw8vAH8Tf6mGJ3zLp8",
  msalConfig: {
    auth: {
      clientId: "b872c50a-bafc-4f15-b263-790723d436ad",
      redirectUri: "https://ut.fsm.northwestern.edu",
      authority: "https://login.microsoftonline.com/nuwildcat.onmicrosoft.com",
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri: "https://ut.fsm.northwestern.edu",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  loginRequest: {
    scopes: ["User.Read"],
    extraQueryParameters: { domain_hint: "northwestern.edu" },
  },
  graphConfig: {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  },
};

let config = null;

switch (process.env.REACT_APP_ENV.toLowerCase()) {
  case "local":
    config = local;
    break;
  case "dev":
    config = dev;
    break;
  case "uat":
    config = uat;
    break;
  case "prod":
    config = prod;
    break;
  default:
    alert("Invalid config!");
    break;
}

let configExport = {
  ...config,
};

export default configExport;
