import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AccessType = ({
  accessTypes,
  accessTypeValue,
  accessTypeDispValue,
  strAccessType,
  accessTypeLabel,
  modalUserInfo,
  setModalUserInfo,
  noAccessMessage,
}) => {
  return (
    <div className="access-container">
      <div className="access-type-label">{accessTypeLabel}</div>
      {modalUserInfo.ACCESS && modalUserInfo.ACCESS[strAccessType] ? (
        <div className="view-access-list">
          {modalUserInfo.ACCESS[strAccessType].split(",").map((accessType) => (
            <div
              className="modal-role-wrap"
              key={accessType}
              onClick={(e) => {
                setModalUserInfo({
                  ...modalUserInfo,
                  ACCESS: {
                    ...modalUserInfo.ACCESS,
                    [strAccessType]: modalUserInfo.ACCESS[strAccessType]
                      .split(",")
                      .filter((accessTypeElem) => accessTypeElem !== accessType)
                      .join(),
                  },
                });
              }}
            >
              <div className="modal-role-label">{accessType}</div>
              <div className="modal-role-remove">
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          ))}
        </div>
      ) : (
        noAccessMessage
      )}
      <div className="access-select-wrap">
        <div className="form-label">Add Access:</div>
        <div className="custom-dd-wrap">
          <select
            value="init"
            onChange={(e) =>
              setModalUserInfo({
                ...modalUserInfo,
                ACCESS: {
                  ...modalUserInfo.ACCESS,
                  [strAccessType]: modalUserInfo.ACCESS[strAccessType]
                    ? modalUserInfo.ACCESS[strAccessType] + "," + e.target.value
                    : e.target.value,
                },
              })
            }
          >
            <option value="init" key="init" disabled>
              Select an access type.
            </option>
            {accessTypes
              .filter((accessType) => {
                if (
                  modalUserInfo.ACCESS &&
                  modalUserInfo.ACCESS[strAccessType]
                ) {
                  if (
                    !modalUserInfo.ACCESS[strAccessType]
                      .split(",")
                      .includes(accessType[accessTypeValue])
                  )
                    return true;
                  else return false;
                } else return true;
              })
              .map((accessType) => (
                <option
                  value={accessType[accessTypeValue]}
                  key={accessType[accessTypeValue]}
                >
                  {accessType[accessTypeDispValue]} (
                  {accessType[accessTypeValue]})
                </option>
              ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default AccessType;
