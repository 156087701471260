import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import {
  faTimes,
  faCircleNotch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Config from "../../config/Config";
import Loader from "../../utils/Loader";
import FilterSelect from "../../utils/FilterSelect";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "white",
    zIndex: "1",
    maxHeight: "90vh",
    overflow: "scroll",
    width: "400px",
    maxWidth: "calc(100% - 60px)",
  },
  overlay: {
    backgroundColor: "rgba(5, 7, 10, .8)",
    zIndex: "4001",
  },
};

const FacultyModal = ({
  modalOpen,
  closeModal,
  facultyInfo,
  refreshFaculty,
  titles,
  titlesMessage,
  titleTypes,
  titleTypesMessage,
}) => {
  const [modalFacultyInfo, setModalFacultyInfo] = useState({});
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    error: false,
    message: "",
  });
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  let { fao_admin_api_root, fao_admin_api_key } = Config;

  useEffect(() => {
    if (facultyInfo.FACULTY_ID) {
      axios
        .get(
          `${fao_admin_api_root}/faoadmin/faculty/${facultyInfo.FACULTY_ID}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": fao_admin_api_key,
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            // Internal Error
            setAPIMessage({
              loading: false,
              error: true,
              ready: false,
              messageText:
                "There was an error retrieving the faculty info: " +
                response.data.error,
            });
          } else if (response.data.data.length > 0) {
            setModalFacultyInfo(response.data.data[0]);
            setAPIMessage({
              loading: false,
              error: false,
              ready: true,
              messageText: "",
            });
          } else {
            setAPIMessage({
              loading: false,
              error: true,
              ready: false,
              messageText:
                "Could not find faculty info for Faculty ID: " +
                facultyInfo.FACULTY_ID,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "Error retrieving faculty info!",
          });
        });
    }
  }, [facultyInfo, fao_admin_api_root, fao_admin_api_key]);

  const handleClose = (event) => {
    event.preventDefault();
    setSubmitStatus({
      loading: false,
      error: false,
      message: "",
    });
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });
    closeModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitStatus({ loading: true });

    let message = {
      titles: modalFacultyInfo.TITLES,
    };

    axios
      .put(
        `${fao_admin_api_root}/faoadmin/faculty/${facultyInfo.FACULTY_ID}`,
        message,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": fao_admin_api_key,
          },
        }
      )
      .then((response) => {
        if (!response.data.error) {
          setSubmitStatus({
            loading: false,
            error: false,
            message: "The faculty member was successfully updated!",
          });
          refreshFaculty();
        } else {
          // Internal Error
          setSubmitStatus({
            loading: false,
            error: true,
            message:
              "There was an error updating the faculty member: " +
              response.data.error,
          });
        }
      })
      .catch((error) => {
        setSubmitStatus({
          loading: false,
          error: true,
          message: "There was an error updating the faculty member!",
        });
        console.log(error);
      });
  };

  const handleUpdateTitles = (updateIndex, property, value, valueType) => {
    let curTitles = [...modalFacultyInfo.TITLES];

    let modTitles = curTitles.map((title, index) => {
      if (index === updateIndex)
        return {
          ...title,
          [property]: valueType === "date" ? value.toISOString() : value,
        };
      else return title;
    });

    setModalFacultyInfo({
      ...modalFacultyInfo,
      TITLES: modTitles,
    });
  };

  const handleAddTitle = () => {
    setModalFacultyInfo({
      ...modalFacultyInfo,
      TITLES: [
        ...modalFacultyInfo.TITLES,
        {
          TITLE_ID: null,
          TITLE_NAME: null,
          START_DATE: new Date().toISOString(),
          END_DATE: new Date().toISOString(),
          CURRENT_FLAG: "n",
        },
      ],
    });
  };

  const handleRemoveTitle = (indexToRemove) => {
    if (window.confirm("Are you sure you want to remove this title?")) {
      // originally used TITLE_ID, but new titles had a null ID
      setModalFacultyInfo({
        ...modalFacultyInfo,
        TITLES: modalFacultyInfo.TITLES.filter(
          (title, index) => index !== indexToRemove
        ),
      });
    }
  };

  const handleOnChangeTitle = (event, updateIndex) => {
    let curTitles = [...modalFacultyInfo.TITLES];

    let modTitles = curTitles.map((title, index) => {
      if (index === updateIndex)
        return {
          ...title,
          TITLE_ID: event.value,
          TITLE_NAME: event.label,
        };
      else return title;
    });

    setModalFacultyInfo({
      ...modalFacultyInfo,
      TITLES: modTitles,
    });
  };

  const handleOnChangeTitleType = (event, updateIndex) => {
    let curTitles = [...modalFacultyInfo.TITLES];

    let modTitles = curTitles.map((title, index) => {
      if (index === updateIndex)
        return {
          ...title,
          TYPE_ID: event.value,
          TYPE_NAME: event.label,
        };
      else return title;
    });

    setModalFacultyInfo({
      ...modalFacultyInfo,
      TITLES: modTitles,
    });
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Edit Faculty"
      closeTimeoutMS={0}
    >
      <div className="modal-inner">
        <div className="modal-close-wrap">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={(event) => handleClose(event)}
          />
        </div>
        <div className="modal-heading">
          {facultyInfo.FIRST_NAME} {facultyInfo.LAST_NAME}
        </div>
        <div className="modal-body-container">
          <form onSubmit={(event) => handleSubmit(event)}>
            {apiMessage.ready ? (
              <>
                <div className="modal-heading">Titles</div>
                <div className="modal-title-group">
                  {modalFacultyInfo.TITLES.map((title, index) => (
                    <div key={index} className="modal-title-wrap">
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="btn-title-remove"
                        onClick={(event) => handleRemoveTitle(index)}
                      />
                      <div className="form-group">
                        <div className="form-label">Title:</div>
                        {title.TITLE_NAME ? (
                          <div className="modal-title-label">
                            {title.TITLE_NAME}
                          </div>
                        ) : titlesMessage.ready ? (
                          <FilterSelect
                            data={titles}
                            dataType="titles"
                            onChange={(event) =>
                              handleOnChangeTitle(event, index)
                            }
                          />
                        ) : (
                          <div>{titlesMessage.messageText}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="form-label">Title Type:</div>
                        {title.TYPE_NAME ? (
                          <div className="modal-title-label">
                            {title.TYPE_NAME}
                          </div>
                        ) : titleTypesMessage.ready ? (
                          <FilterSelect
                            data={titleTypes}
                            dataType="titleTypes"
                            onChange={(event) =>
                              handleOnChangeTitleType(event, index)
                            }
                          />
                        ) : (
                          <div>{titleTypesMessage.messageText}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="form-label">Start Date:</div>
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          selected={
                            title.START_DATE
                              ? new Date(title.START_DATE)
                              : new Date()
                          }
                          onChange={(date) =>
                            handleUpdateTitles(
                              index,
                              "START_DATE",
                              date,
                              "date"
                            )
                          }
                        />
                      </div>
                      <div className="form-group">
                        <div className="form-label">End Date:</div>
                        {title.END_DATE ? (
                          <DatePicker
                            dateFormat="MM/dd/yyyy"
                            selected={
                              title.END_DATE
                                ? new Date(title.END_DATE)
                                : new Date()
                            }
                            onChange={(date) =>
                              handleUpdateTitles(
                                index,
                                "END_DATE",
                                date,
                                "date"
                              )
                            }
                          />
                        ) : (
                          <div>
                            An end date is not specified for this title.{" "}
                            <span
                              className="text-add-end-date"
                              onClick={(event) =>
                                handleUpdateTitles(
                                  index,
                                  "END_DATE",
                                  new Date(),
                                  "date"
                                )
                              }
                            >
                              Add?
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="form-label">Current Flag:</div>
                        <div className="custom-dd-wrap">
                          <select
                            defaultValue={title.CURRENT_FLAG}
                            onChange={(event) =>
                              handleUpdateTitles(
                                index,
                                "CURRENT_FLAG",
                                event.target.value,
                                "string"
                              )
                            }
                          >
                            <option value="n">Not Current</option>
                            <option value="c">Current</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="modal-title-add-wrap">
                    <button
                      type="button"
                      className="btn-sm"
                      onClick={(event) => handleAddTitle()}
                    >
                      New Title
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <Loader message={apiMessage} />
            )}
            <div className="btn-group two">
              <button type="submit" className="btn-sm">
                Submit
                {submitStatus.loading && (
                  <span>
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      className="btn-processing fa-spin"
                    />
                  </span>
                )}
              </button>
              <button
                type="button"
                className="btn-sm secondary"
                onClick={(event) => handleClose(event)}
              >
                Close
              </button>
            </div>
            {submitStatus.message !== "" && (
              <div
                className={`submit-msg ${submitStatus.error ? "error" : ""}`}
              >
                {submitStatus.message}
              </div>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default FacultyModal;
