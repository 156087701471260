import React from "react";
import ToolMenu from "../../utils/ToolMenu";
import PrivLocAdmin from "./PrivLocAdmin";
import { Route, Switch } from "react-router-dom";

const HomeFAOPrivLoc = () => {
  return (
    <div className="main-content-outer">
      <div className="main-content-inner constrain-1260">
        <ToolMenu app="faoPrivLoc" appURL="/fao/privloc" />
        <div className="app-content-container">
          <Switch>
            <Route exact path="/fao/privloc" component={PrivLocAdmin} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default HomeFAOPrivLoc;
