import React from "react";
import { NavLink } from "react-router-dom";
import { Tools } from "../config/Apps";

const ToolMenu = ({ app, appURL }) => {
  return (
    <div className="tool-menu-container">
      {Tools[app] ? (
        Tools[app].map((tool) => {
          return tool.default ? (
            <NavLink exact to={appURL} key={tool.name}>
              <div className="menu-elem">{tool.dispName}</div>
            </NavLink>
          ) : (
            <NavLink to={`${appURL}/${tool.route}`} key={tool.name}>
              <div className="menu-elem">{tool.dispName}</div>
            </NavLink>
          );
        })
      ) : (
        <div className="menu-elem">No tools found for {app}.</div>
      )}
    </div>
  );
};

export default ToolMenu;
