import React from "react";
import ToolMenu from "../../utils/ToolMenu";
import MissingDataAdmin from "./MissingDataAdmin";
import { Route, Switch } from "react-router-dom";

const HomeFAOMissingData = () => {
  return (
    <div className="main-content-outer">
      <div className="main-content-inner constrain-1260">
        <ToolMenu app="faoMissingData" appURL="/fao/missing-data" />
        <div className="app-content-container">
          <Switch>
            <Route
              exact
              path="/fao/missing-data"
              component={MissingDataAdmin}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default HomeFAOMissingData;
