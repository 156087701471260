import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import SortTable from "../../utils/SortTable";
import Config from "../../config/Config";
import Loader from "../../utils/Loader";
import TitleTypesModal from "./TitleTypesModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

const columns = [
  {
    Header: "Type ID",
    accessor: "TYPE_ID",
    sticky: "left",
  },
  {
    Header: "Type Name",
    accessor: "TYPE_NAME",
  },
  {
    Header: "Active",
    accessor: "ACTIVE",
  },
  {
    Header: "Display Order",
    accessor: "DISPLAY_ORDER",
  },
  {
    Header: "Create Date",
    accessor: "CREATE_DATE",
    type: "date",
  },
  {
    Header: "Create User",
    accessor: "CREATE_USER",
  },
  {
    Header: "Modify Date",
    accessor: "MODIFY_DATE",
    type: "date",
  },
  {
    Header: "Modify User",
    accessor: "MODIFY_USER",
  },
];

const defaultSorted = [{ id: "TYPE_ID", desc: false }];

const TitleTypesAdmin = () => {
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [titleTypes, setTitleTypes] = useState([]);
  const [titleTypeInfo, setTitleTypeInfo] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(false);

  const handleRefreshTitleTypes = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    axios
      .get(fao_admin_api_root + "/faoadmin/titletypes", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the title types: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setTitleTypes(response.data.data);
          setAPIMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no title types.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAPIMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving title types!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key]);

  useEffect(() => {
    handleRefreshTitleTypes();
  }, [handleRefreshTitleTypes]);

  const handleAddClick = (event) => {
    setTitleTypeInfo({});
    setModalAction("Add");
    setModalOpen(true);
  };

  const handleEditClick = (event, row) => {
    setTitleTypeInfo(row.values);
    setModalAction("Edit");
    setModalOpen(true);
  };

  return (
    <div className="message-center-container">
      <div className="tool-title">FAO Title Types Admin</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The FAO Titles Types Admin tool can be used to manage title types that
          are applied to faculty member titles.
        </div>
        <div className="tool-feature-list">
          <div className="tool-feature-label">Tool Features:</div>
          <ul>
            <li>Title types can be added or updated.</li>
            <li>
              The following fields can be modified for each title type: Title
              Type Name, Active Indicator, Display Order.
            </li>
            <li>
              Faculty Members can be managed via the{" "}
              <NavLink className="nav-link" to="/fao/faculty">
                FAO Faculty Admin
              </NavLink>{" "}
              tool.
            </li>
            <li>
              Titles can be managed via the{" "}
              <NavLink className="nav-link" to="/fao/titles">
                Titles Admin
              </NavLink>{" "}
              tool.
            </li>
          </ul>
        </div>
      </div>
      <div className="table-container">
        <div className="btn-add-message-wrap">
          <button onClick={(event) => handleAddClick()}>
            <FontAwesomeIcon icon={faPlus} className="btn-add-message" />
            Add Title Type
          </button>
        </div>
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={titleTypes}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              paginationEnabled={true}
              editClick={handleEditClick}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
      <TitleTypesModal
        modalOpen={modalOpen}
        modalAction={modalAction}
        closeModal={(event) => setModalOpen(false)}
        titleTypeInfo={titleTypeInfo}
        refreshTitleTypes={handleRefreshTitleTypes}
      />
    </div>
  );
};

export default TitleTypesAdmin;
