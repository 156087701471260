import React, { useState, useCallback } from "react";
import { useEffect } from "react";
import axios from "axios";
import SortTable from "../utils/SortTable";
import Loader from "../utils/Loader";
import Config from "../config/Config";
import MessageCenterModal from "./MessageCenterModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "../context/UserContext";

const columns = [
  {
    Header: "ID",
    accessor: "messageID",
    sticky: "left",
  },
  {
    Header: "Type",
    accessor: "messageType",
  },
  {
    Header: "Message",
    accessor: "message",
    type: "paragraph",
  },
  {
    Header: "Start Date",
    accessor: "startDate",
    type: "date",
  },
  {
    Header: "End Date",
    accessor: "endDate",
    type: "date",
  },
];

const defaultSorted = [{ id: "startDate", desc: true }];

const MessageCenter = () => {
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [messages, setMessages] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [messageInfo, setMessageInfo] = useState({});
  let { mc_api_root, mc_api_key } = Config;
  let { accessToken } = useUserContext();

  const handleRefreshMessages = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    if (accessToken) {
      axios
        .get(mc_api_root + "/message", {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": mc_api_key,
            "access-token": accessToken,
          },
        })
        .then((response) => {
          if (response.data.error) {
            // Internal Error
            setAPIMessage({
              loading: false,
              error: true,
              ready: false,
              messageText:
                "There was an error retrieving the messages: " +
                response.data.error,
            });
          } else if (response.data.length > 0) {
            setMessages(response.data);
            setAPIMessage({
              loading: false,
              error: false,
              ready: true,
              messageText: "",
            });
          } else {
            setAPIMessage({
              loading: false,
              error: true,
              ready: false,
              messageText: "There are currently no messages.",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "Error retrieving messages!",
          });
        });
    }
  }, [mc_api_root, mc_api_key, accessToken]);

  useEffect(() => {
    handleRefreshMessages();
  }, [handleRefreshMessages]);

  const handleEditClick = (event, row) => {
    setModalAction("Edit");
    setMessageInfo(row.values);
    setModalOpen(true);
  };

  const handleAddClick = (event) => {
    setModalAction("Add");
    setMessageInfo({});
    setModalOpen(true);
  };

  const handleDeleteClick = (event, row) => {
    if (window.confirm("Are you sure you want to delete this message?")) {
      setAPIMessage({
        loading: true,
        error: false,
        ready: false,
        messageText: "Loading...",
      });

      if (accessToken) {
        axios
          .delete(mc_api_root + "/message/" + row.values.messageID, {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": mc_api_key,
              "access-token": accessToken,
            },
          })
          .then((response) => {
            if (!response.data.error) handleRefreshMessages();
            else alert("Could not delete the message: " + response.data.error);
          })
          .catch((error) => {
            console.log(error);
            alert("Could not delete the message!");
          });
      }
    }
  };

  return (
    <div className="message-center-container">
      <div className="tool-title">Message Center</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The Message Center tool can be used to display banner messages in the
          Focus/eMerg systems.
        </div>
        <div className="tool-feature-list">
          <div className="tool-feature-label">Tool Features:</div>
          <ul>
            <li>
              Banner messages will be displayed during the Start Date and End
              Date timeframe
            </li>
            <li>
              Banner messages can be displayed as Warnings, Information, or
              Danger.
            </li>
          </ul>
        </div>
      </div>
      <div className="table-container">
        <div className="btn-add-message-wrap">
          <button onClick={(event) => handleAddClick()}>
            <FontAwesomeIcon icon={faPlus} className="btn-add-message" />
            Add Message
          </button>
        </div>
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={messages}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              editClick={handleEditClick}
              deleteClick={handleDeleteClick}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
      <MessageCenterModal
        modalOpen={modalOpen}
        closeModal={(event) => setModalOpen(false)}
        action={modalAction}
        messageInfo={messageInfo}
        refreshMessages={handleRefreshMessages}
      />
    </div>
  );
};

export default MessageCenter;
