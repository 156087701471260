import React from "react";
import Select, { components } from "react-select";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const customStyles = {
  container: (base) => ({
    ...base,
    height: 45,
    minHeight: 45,
  }),
  control: (base) => ({
    ...base,
    height: 45,
    minHeight: 45,
    borderRadius: 0,
    cursor: "pointer",
  }),
  valueContainer: (base) => ({
    ...base,
    height: 45,
    minHeight: 45,
    overflow: "unset",
  }),
  input: (base) => ({
    ...base,
    marginTop: -4,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#4e2a84",
  }),
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faChevronDown} className="react-select-chevron" />
    </components.DropdownIndicator>
  );
};

const transformData = (data, dataType) => {
  if (dataType === "titles") {
    return data.map((dataElem) => {
      return {
        value: dataElem.TITLE_ID,
        label: dataElem.TITLE_NAME,
      };
    });
  } else if (dataType === "titleTypes") {
    return data.map((dataElem) => {
      return {
        value: dataElem.TYPE_ID,
        label: dataElem.TYPE_NAME,
      };
    });
  } else if (dataType === "buildings") {
    return data.map((dataElem) => {
      return {
        value: dataElem.LOCATION_ID,
        label: `${dataElem.LOCATION_ID} (${dataElem.LOCATION})`,
      };
    });
  }
};

const FilterSelect = ({ value, data, dataType, onChange }) => (
  <Select
    value={value}
    components={{ DropdownIndicator }}
    options={transformData(data, dataType)}
    styles={customStyles}
    isClearable={true}
    onChange={onChange}
  />
);

export default FilterSelect;
