import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import SortTable from "../../utils/SortTable";
import Config from "../../config/Config";
import Loader from "../../utils/Loader";
import { MissingTypes } from "../../config/MissingTypes";
import { convertDate } from "../../utils/DataTransformation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const columns = [
  {
    Header: "Name",
    accessor: "NAME",
    sticky: "left",
    type: "nowrap",
  },
  {
    Header: "Faculty ID",
    accessor: "FACULTY_ID",
  },
  {
    Header: "SSN Status",
    accessor: "SSN",
  },
  {
    Header: "Employee ID",
    accessor: "EMPLOYEE_ID",
  },
  {
    Header: "NetID",
    accessor: "NET_ID",
  },
  {
    Header: "Email",
    accessor: "EMAIL",
  },
  {
    Header: "Division Abbreviation",
    accessor: "DV_ABBR",
  },
  {
    Header: "Category",
    accessor: "CATEGORY",
  },
  {
    Header: "Basis",
    accessor: "BASIS",
  },
  {
    Header: "Rank",
    accessor: "RANK",
  },
  {
    Header: "Appointment Start",
    accessor: "APPT_START",
  },
];

const defaultSorted = [{ id: "NAME", desc: false }];

const MissingDataAdmin = () => {
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [missingData, setMissingData] = useState([]);
  const [curMissingType, setCurMissingType] = useState("all");
  const [filterText, setFilterText] = useState("");

  const handleRefreshMissingData = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    axios
      .get(
        `${fao_admin_api_root}/faoadmin/missingdata/${
          curMissingType !== "all" ? curMissingType : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": fao_admin_api_key,
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the missing data: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setMissingData(removeArrayProps(response.data.data));
          setAPIMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There is currently no missing data.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAPIMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving missing data!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key, curMissingType]);

  useEffect(() => {
    handleRefreshMissingData();
  }, [handleRefreshMissingData]);

  const removeArrayProps = (array) =>
    array.map((arrayElem) => {
      return {
        NAME: arrayElem.NAME,
        FACULTY_ID: arrayElem.FACULTY_ID,
        SSN: arrayElem.SSN,
        EMPLOYEE_ID: arrayElem.EMPLOYEE_ID,
        NET_ID: arrayElem.NET_ID,
        EMAIL: arrayElem.EMAIL,
        DV_ABBR: arrayElem.DV_ABBR,
        CATEGORY: arrayElem.CATEGORY,
        BASIS: arrayElem.BASIS,
        RANK: arrayElem.RANK,
        APPT_START: convertDate(arrayElem.APPT_START),
      };
    });

  const filterData = (data) => {
    return data.filter((dataElem) => {
      var bFound = false;
      columns.forEach((column) => {
        if (
          dataElem[column.accessor] &&
          dataElem[column.accessor]
            .toString()
            .toLowerCase()
            .includes(filterText.toLowerCase())
        )
          bFound = true;
      });
      return bFound;
    });
  };

  return (
    <div className="privilege-location-container">
      <div className="tool-title">FAO Missing Data Admin</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The FAO Missing Data Admin tool can be used to view missing data that
          is associated with Faculty Affairs.
        </div>
        <div className="tool-feature-list">
          <div className="tool-feature-label">Tool Features:</div>
          <ul>
            <li>Missing data is filterable by type.</li>
          </ul>
        </div>
      </div>
      <div className="filtering-container">
        <div className="filter-title">Filtering</div>
        <div className="filter-inputs-wrap">
          <div className="filter-dd-wrap">
            <div className="custom-dd-wrap">
              <select
                value={curMissingType || "all"}
                onChange={(e) => setCurMissingType(e.target.value)}
              >
                <option value="all">All Types</option>
                {MissingTypes.map((missingType) => (
                  <option value={missingType.value} key={missingType.value}>
                    {missingType.dispValue}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="input-icon-wrap">
            <div className="input-icon">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              value={filterText}
              type="text"
              placeholder="Search fields"
              autoComplete="off"
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
        </div>
        <button
          onClick={(event) => {
            setCurMissingType("all");
            setFilterText("");
          }}
        >
          Clear Filters
        </button>
      </div>
      <div className="table-container">
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={filterData(missingData)}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              paginationEnabled={true}
              exportEnabled={{
                filename: "missingData.csv",
              }}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
    </div>
  );
};

export default MissingDataAdmin;
