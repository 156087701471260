import React from "react";
import ToolMenu from "../../utils/ToolMenu";
import HSCSponsorsAdmin from "./HSCSponsorsAdmin";
import { Route, Switch } from "react-router-dom";

const HomeFAOHSCSponsors = () => {
  return (
    <div className="main-content-outer">
      <div className="main-content-inner constrain-1260">
        <ToolMenu app="faoHSCSponsors" appURL="/fao/hsc-sponsors" />
        <div className="app-content-container">
          <Switch>
            <Route
              exact
              path="/fao/hsc-sponsors"
              component={HSCSponsorsAdmin}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default HomeFAOHSCSponsors;
