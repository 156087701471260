import React from "react";
import ToolMenu from "../../utils/ToolMenu";
import DivisionsAdmin from "./DivisionsAdmin";
import { Route, Switch } from "react-router-dom";

const HomeFAODivisions = () => {
  return (
    <div className="main-content-outer">
      <div className="main-content-inner constrain-1260">
        <ToolMenu app="faoDivisions" appURL="/fao/divisions" />
        <div className="app-content-container">
          <Switch>
            <Route exact path="/fao/divisions" component={DivisionsAdmin} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default HomeFAODivisions;
