import React from "react";
import ToolMenu from "../../utils/ToolMenu";
import DepartmentsAdmin from "./DepartmentsAdmin";
import { Route, Switch } from "react-router-dom";

const HomeFAODepartments = () => {
  return (
    <div className="main-content-outer">
      <div className="main-content-inner constrain-1260">
        <ToolMenu app="faoDepartments" appURL="/fao/departments" />
        <div className="app-content-container">
          <Switch>
            <Route exact path="/fao/departments" component={DepartmentsAdmin} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default HomeFAODepartments;
