import React from "react";
import Routes from "./Routes";
import UserContextProvider from "../context/UserContext";
import { CookiesProvider } from "react-cookie";

const App = () => {
  return (
    <div className="app-container">
      <CookiesProvider>
        <UserContextProvider>
          <Routes />
        </UserContextProvider>
      </CookiesProvider>
    </div>
  );
};

export default App;
