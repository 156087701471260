import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import SortTable from "../../utils/SortTable";
import Config from "../../config/Config";
import Loader from "../../utils/Loader";
import ProfessorshipsModal from "./ProfessorshipsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

const columns = [
  {
    Header: "Sort Name",
    accessor: "SORT_NAME",
    sticky: "left",
  },
  {
    Header: "ID",
    accessor: "ID",
  },
  {
    Header: "Professorship Name",
    accessor: "PROFESSORSHIP_NAME",
  },
  {
    Header: "Display Name",
    accessor: "DISPLAY_NAME",
  },
  {
    Header: "Fund",
    accessor: "FUND",
  },
  {
    Header: "Active Flag",
    accessor: "ACTIVE_FLAG",
  },
  {
    Header: "Project Number",
    accessor: "PROJECT_NUMBER",
  },
  {
    Header: "FASIS ID",
    accessor: "FASIS_ID",
  },
  {
    Header: "FASIS Code",
    accessor: "FASIS_CODE",
  },
  {
    Header: "FASIS Number",
    accessor: "FASIS_NUMBER",
  },
  {
    Header: "Professorship Department",
    accessor: "PROFESSORSHIP_DEPARTMENT",
  },
];

const defaultSorted = [{ id: "SORT_NAME", desc: false }];

const ProfessorshipsAdmin = () => {
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [professorships, setProfessorships] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(false);
  const [professorshipInfo, setProfessorshipInfo] = useState({});
  const [filterText, setFilterText] = useState("");

  const handleRefreshProfessorships = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    axios
      .get(fao_admin_api_root + "/faoadmin/professorships", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the professorships: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setProfessorships(response.data.data);
          setAPIMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no professorships.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAPIMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving professorships!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key]);

  useEffect(() => {
    handleRefreshProfessorships();
  }, [handleRefreshProfessorships]);

  const handleAddClick = (event) => {
    setProfessorshipInfo({});
    setModalAction("Add");
    setModalOpen(true);
  };

  const handleEditClick = (event, row) => {
    setProfessorshipInfo(row.values);
    setModalAction("Edit");
    setModalOpen(true);
  };

  const filterData = (data) => {
    return data.filter((dataElem) => {
      var bFound = false;
      columns.forEach((column) => {
        if (
          dataElem[column.accessor] &&
          dataElem[column.accessor]
            .toString()
            .toLowerCase()
            .includes(filterText.toLowerCase())
        )
          bFound = true;
      });
      return bFound;
    });
  };

  return (
    <div className="professorship-admin-container">
      <div className="tool-title">Professorships Admin</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The Professorships Admin tool can be used to perform various tasks on
          professorships.
        </div>
        <div className="tool-feature-list">
          <div className="tool-feature-label">Tool Features:</div>
          <ul>
            <li>Professorships can be added and modified.</li>
            <li>
              The following fields can be modified for each professorship:
              active_flag, sort_name, professorship_name, display_name, fund,
              project_number, fasis_id, fasis_code, fasis_number,
              professorship_department.
            </li>
          </ul>
        </div>
      </div>
      <div className="filtering-container">
        <div className="filter-title">Filtering</div>
        <div className="filter-inputs-wrap one">
          <div className="input-icon-wrap">
            <div className="input-icon">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              value={filterText}
              type="text"
              placeholder="Search fields"
              autoComplete="off"
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
        </div>
        <button onClick={(event) => setFilterText("")}>Clear Filter</button>
      </div>
      <div className="table-container">
        <div className="btn-add-message-wrap">
          <button onClick={(event) => handleAddClick()}>
            <FontAwesomeIcon icon={faPlus} className="btn-add-message" />
            Add Professorship
          </button>
        </div>
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={filterData(professorships)}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              paginationEnabled={true}
              editClick={handleEditClick}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
      <ProfessorshipsModal
        modalOpen={modalOpen}
        modalAction={modalAction}
        closeModal={(event) => setModalOpen(false)}
        professorshipInfo={professorshipInfo}
        refreshProfessorships={handleRefreshProfessorships}
      />
    </div>
  );
};

export default ProfessorshipsAdmin;
