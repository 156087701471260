import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import SortTable from "../../utils/SortTable";
import Config from "../../config/Config";
import Loader from "../../utils/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";
import UsersSearchModal from "./UsersSearchModal";

const columns = [
  {
    Header: "NetID",
    accessor: "NETID",
    sticky: "left",
  },
  {
    Header: "Full Name",
    accessor: "FULLNAME",
  },
  {
    Header: "Email",
    accessor: "EMAIL",
  },
  {
    Header: "Organization Unit",
    accessor: "ORG_UNIT",
  },
  {
    Header: "Last Login",
    accessor: "LAST_LOGIN",
    type: "date",
  },
  {
    Header: "Description",
    accessor: "DESCRIPTION",
  },
  {
    Header: "Division Abbreviation",
    accessor: "DV_ABBR",
  },
];

const defaultSorted = [{ id: "NETID", desc: false }];

const UsersSearchAdmin = () => {
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [users, setUsers] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [userInfo, setUserInfo] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(false);

  const handleRefreshUsers = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    axios
      .get(fao_admin_api_root + "/faoadmin/users/search", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the users: " + response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setUsers(response.data.data);
          setAPIMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no users.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAPIMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving users!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key]);

  useEffect(() => {
    handleRefreshUsers();
  }, [handleRefreshUsers]);

  const filterData = (data) => {
    return data.filter((dataElem) => {
      var bFound = false;
      columns.forEach((column) => {
        if (
          dataElem[column.accessor] &&
          dataElem[column.accessor]
            .toString()
            .toLowerCase()
            .includes(filterText.toLowerCase())
        )
          bFound = true;
      });
      return bFound;
    });
  };

  const handleAddClick = (event) => {
    setUserInfo({ ACCESS: {} });
    setModalAction("Add");
    setModalOpen(true);
  };

  const handleEditClick = (event, row) => {
    setUserInfo(row.values);
    setModalAction("Edit");
    setModalOpen(true);
  };

  return (
    <div className="privilege-location-container">
      <div className="tool-title">FAO Users Search Admin</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The FAO Users Search Admin tool can be used to view users that are
          associated with Faculty Affairs.
        </div>
        <div className="tool-feature-list">
          <div className="tool-feature-label">Tool Features:</div>
          <ul>
            <li>Users are filterable by text in any field.</li>
          </ul>
        </div>
      </div>
      <div className="filtering-container">
        <div className="filter-title">Filtering</div>
        <div className="filter-inputs-wrap one">
          <div className="input-icon-wrap">
            <div className="input-icon">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              value={filterText}
              type="text"
              placeholder="Search fields"
              autoComplete="off"
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
        </div>
        <button onClick={(event) => setFilterText("")}>Clear Filter</button>
      </div>
      <div className="table-container">
        <div className="btn-add-message-wrap">
          <button onClick={(event) => handleAddClick()}>
            <FontAwesomeIcon icon={faPlus} className="btn-add-message" />
            Add User
          </button>
        </div>
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={filterData(users)}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              paginationEnabled={true}
              editClick={handleEditClick}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
      <UsersSearchModal
        modalOpen={modalOpen}
        modalAction={modalAction}
        closeModal={(event) => setModalOpen(false)}
        userInfo={userInfo}
        refreshUsers={(event) => handleRefreshUsers()}
      />
    </div>
  );
};

export default UsersSearchAdmin;
