import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { faTimes, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Config from "../../config/Config";
import { useUserContext } from "../../context/UserContext";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "white",
    zIndex: "1",
    maxHeight: "90vh",
    overflow: "scroll",
    width: "400px",
    maxWidth: "calc(100% - 60px)",
  },
  overlay: {
    backgroundColor: "rgba(5, 7, 10, .8)",
    zIndex: "4001",
  },
};

const HSCSponsorsModal = ({
  modalOpen,
  modalAction,
  closeModal,
  hscSponsorsInfo,
  refreshHSCSponsors,
}) => {
  const [modalHSCSponsorsInfo, setModalHSCSponsorsInfo] = useState({});
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    error: false,
    message: "",
  });
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const context = useUserContext();

  let { netid } = context.userUT || {};

  useEffect(() => {
    if (hscSponsorsInfo) setModalHSCSponsorsInfo(hscSponsorsInfo);
  }, [hscSponsorsInfo]);

  const handleClose = (event) => {
    event.preventDefault();
    setModalHSCSponsorsInfo({});
    setSubmitStatus({
      loading: false,
      error: false,
      message: "",
    });
    closeModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitStatus({ loading: true });

    if (modalAction === "Add") {
      let message = {
        create_user: netid,
        hsc_sponsor: modalHSCSponsorsInfo.HSC_SPONSOR,
        is_active:
          modalHSCSponsorsInfo.IS_ACTIVE &&
          modalHSCSponsorsInfo.IS_ACTIVE === "Yes"
            ? "Y"
            : "N",
      };

      axios
        .post(`${fao_admin_api_root}/faoadmin/hscsponsors`, message, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": fao_admin_api_key,
          },
        })
        .then((response) => {
          if (!response.data.error) {
            setSubmitStatus({
              loading: false,
              error: false,
              message: "The HSC sponsor was successfully added!",
            });
            refreshHSCSponsors();
          } else {
            // Internal Error
            setSubmitStatus({
              loading: false,
              error: true,
              message:
                "There was an error adding the HSC sponsor: " +
                response.data.error,
            });
          }
        })
        .catch((error) => {
          setSubmitStatus({
            loading: false,
            error: true,
            message: "There was an error adding the HSC sponsor!",
          });
          console.log(error);
        });
    } else if (modalAction === "Edit") {
      let message = {
        modify_user: netid,
        hsc_sponsor: modalHSCSponsorsInfo.HSC_SPONSOR,
        is_active:
          modalHSCSponsorsInfo.IS_ACTIVE &&
          modalHSCSponsorsInfo.IS_ACTIVE === "Yes"
            ? "Y"
            : "N",
      };

      axios
        .put(
          `${fao_admin_api_root}/faoadmin/hscsponsors/${hscSponsorsInfo.HSC_SPONSOR_ID}`,
          message,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": fao_admin_api_key,
            },
          }
        )
        .then((response) => {
          if (!response.data.error) {
            setSubmitStatus({
              loading: false,
              error: false,
              message: "The HSC sponsor was successfully updated!",
            });
            refreshHSCSponsors();
          } else {
            // Internal Error
            setSubmitStatus({
              loading: false,
              error: true,
              message:
                "There was an error updating the HSC sponsor: " +
                response.data.error,
            });
          }
        })
        .catch((error) => {
          setSubmitStatus({
            loading: false,
            error: true,
            message: "There was an error updating the HSC sponsor!",
          });
          console.log(error);
        });
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Edit Division"
      closeTimeoutMS={0}
    >
      <div className="modal-inner">
        <div className="modal-close-wrap">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={(event) => handleClose(event)}
          />
        </div>
        <div className="modal-heading">{modalAction} HSC Sponsor</div>
        <div className="modal-body-container">
          <form onSubmit={(event) => handleSubmit(event)}>
            {modalHSCSponsorsInfo.HSC_SPONSOR_ID && (
              <div className="form-group">
                <div className="form-label">HSC Sponsor ID:</div>
                <input
                  type="text"
                  required
                  placeholder=""
                  autoComplete="off"
                  disabled
                  value={modalHSCSponsorsInfo.HSC_SPONSOR_ID}
                />
              </div>
            )}
            <div className="form-group">
              <div className="form-label">HSC Sponsor:</div>
              <input
                type="text"
                placeholder=""
                autoComplete="off"
                value={modalHSCSponsorsInfo.HSC_SPONSOR || ""}
                onChange={(e) =>
                  setModalHSCSponsorsInfo({
                    ...modalHSCSponsorsInfo,
                    HSC_SPONSOR: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <div className="form-label">Active Indicator:</div>
              <div className="custom-dd-wrap">
                <select
                  value={modalHSCSponsorsInfo.IS_ACTIVE || "init"}
                  required
                  onChange={(e) =>
                    setModalHSCSponsorsInfo({
                      ...modalHSCSponsorsInfo,
                      IS_ACTIVE: e.target.value,
                    })
                  }
                >
                  <option value="init" disabled>
                    Select active indicator.
                  </option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
            <div className="btn-group two">
              <button type="submit" className="btn-sm">
                Submit
                {submitStatus.loading && (
                  <span>
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      className="btn-processing fa-spin"
                    />
                  </span>
                )}
              </button>
              <button
                type="button"
                className="btn-sm secondary"
                onClick={(event) => handleClose(event)}
              >
                Close
              </button>
            </div>
            {submitStatus.message !== "" && (
              <div
                className={`submit-msg ${submitStatus.error ? "error" : ""}`}
              >
                {submitStatus.message}
              </div>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default HSCSponsorsModal;
