import React from "react";
import ToolMenu from "../../utils/ToolMenu";
import FacultyAdmin from "./FacultyAdmin";
import FacultyNoteTypesAdmin from "./FacultyNoteTypesAdmin";
import { Route, Switch } from "react-router-dom";

const HomeFAOFaculty = () => {
  return (
    <div className="main-content-outer">
      <div className="main-content-inner constrain-1260">
        <ToolMenu app="faoFaculty" appURL="/fao/faculty" />
        <div className="app-content-container">
          <Switch>
            <Route exact path="/fao/faculty" component={FacultyAdmin} />
            <Route
              exact
              path="/fao/faculty/note-types"
              component={FacultyNoteTypesAdmin}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default HomeFAOFaculty;
