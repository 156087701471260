import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import SortTable from "../utils/SortTable";
import Config from "../config/Config";
import Loader from "../utils/Loader";
import PortalReportsManagementModal from "./PortalReportsManagementModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const columns = [
  {
    Header: "NetID",
    accessor: "NETID",
    sticky: "left",
  },
  {
    Header: "First Name",
    accessor: "FIRST_NAME",
  },
  {
    Header: "Last Name",
    accessor: "LAST_NAME",
  },
  {
    Header: "Role List",
    accessor: "ROLES",
    type: "role-list",
  },
];

const defaultSorted = [{ id: "NETID", desc: false }];

const sortRoles = (a, b) => {
  if (a.ROLE_ID < b.ROLE_ID) return -1;
  else return 1;
};

const PortalReportsManagement = () => {
  let { portal_api_root, portal_api_key } = Config;
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [users, setUsers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [userInfo, setUserInfo] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filterRole, setFilterRole] = useState("init");
  const [roles, setRoles] = useState([]);
  const [rolesMessage, setRolesMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });

  const handleRefreshUsers = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    axios
      .get(portal_api_root + "/portal", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": portal_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the users: " + response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setUsers(response.data.data);
          setAPIMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no users.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAPIMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving users!",
        });
      });
  }, [portal_api_root, portal_api_key]);

  useEffect(() => {
    handleRefreshUsers();
  }, [handleRefreshUsers]);

  useEffect(() => {
    axios
      .get(portal_api_root + "/portal/roles", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": portal_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setRolesMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the roles: " + response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setRoles(response.data.data.sort(sortRoles));
          setRolesMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setRolesMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no roles.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setRolesMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving roles!",
        });
      });
  }, [portal_api_root, portal_api_key]);

  const handleEditClick = (event, row) => {
    setModalAction("Edit");
    setUserInfo(row.values);
    setModalOpen(true);
  };

  return (
    <div className="message-center-container">
      <div className="tool-title">Portal Reports Management</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The Portal Reports Management tool can be used to manage the reports
          tab in the portal.
        </div>
        <div className="tool-feature-list">
          <div className="tool-feature-label">Tool Features:</div>
          <ul>
            <li>Roles assigned to users can be modified.</li>
          </ul>
        </div>
      </div>
      <div className="filtering-container">
        <div className="filter-title">Filtering</div>
        <div className="filter-inputs-wrap">
          <div className="input-icon-wrap">
            <div className="input-icon">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              value={filterText}
              type="text"
              placeholder="Search name fields"
              autoComplete="off"
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
          <div className="custom-dd-wrap">
            <select
              value={filterRole}
              onChange={(e) => setFilterRole(e.target.value)}
            >
              <option value="init" key="init" disabled>
                Select a role.
              </option>
              {roles.map((role) => (
                <option value={role.ROLE_ID} key={role.ROLE_ID}>
                  {role.ROLE_ID} -- {role.ROLE_NAME}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button
          onClick={(event) => {
            setFilterText("");
            setFilterRole("init");
          }}
        >
          Clear Filters
        </button>
      </div>
      <div className="table-container">
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={users
                .filter((user) => {
                  if (filterRole === "init") return user;
                  else
                    return user.ROLES.some(
                      (role) =>
                        role.ROLE_ID.toString() === filterRole.toString()
                    );
                })
                .filter((user) => {
                  return (
                    (user["NETID"] &&
                      user["NETID"]
                        .toString()
                        .toLowerCase()
                        .includes(filterText.toLowerCase())) ||
                    (user["FIRST_NAME"] &&
                      user["FIRST_NAME"]
                        .toString()
                        .toLowerCase()
                        .includes(filterText.toLowerCase())) ||
                    (user["LAST_NAME"] &&
                      user["LAST_NAME"]
                        .toString()
                        .toLowerCase()
                        .includes(filterText.toLowerCase()))
                  );
                })}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              editClick={handleEditClick}
              deleteClick={false}
              paginationEnabled={true}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
      <PortalReportsManagementModal
        modalOpen={modalOpen}
        closeModal={(event) => setModalOpen(false)}
        action={modalAction}
        userInfo={userInfo}
        refreshUsers={handleRefreshUsers}
        roles={roles}
        rolesMessage={rolesMessage}
      />
    </div>
  );
};

export default PortalReportsManagement;
