import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { faTimes, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Config from "../../config/Config";
import { useUserContext } from "../../context/UserContext";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "white",
    zIndex: "1",
    maxHeight: "90vh",
    overflow: "scroll",
    width: "400px",
    maxWidth: "calc(100% - 60px)",
  },
  overlay: {
    backgroundColor: "rgba(5, 7, 10, .8)",
    zIndex: "4001",
  },
};

const DepartmentsModal = ({
  modalOpen,
  modalAction,
  closeModal,
  departmentsInfo,
  refreshDepartments,
}) => {
  const [modalDepartmentsInfo, setModalDepartmentsInfo] = useState({});
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    error: false,
    message: "",
  });
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const context = useUserContext();

  let { netid } = context.userUT || {};

  useEffect(() => {
    if (departmentsInfo) setModalDepartmentsInfo(departmentsInfo);
  }, [departmentsInfo]);

  const handleClose = (event) => {
    event.preventDefault();
    setModalDepartmentsInfo({});
    setSubmitStatus({
      loading: false,
      error: false,
      message: "",
    });
    closeModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitStatus({ loading: true });

    let message = {
      modify_user: netid,
      dv_url: modalDepartmentsInfo.DV_URL,
      dv_room_number: modalDepartmentsInfo.DV_ROOM_NUMBER,
      dv_phone: modalDepartmentsInfo.DV_PHONE,
      dv_location_id: modalDepartmentsInfo.DV_LOCATION_ID,
      search_name: modalDepartmentsInfo.SEARCH_NAME,
      label_name: modalDepartmentsInfo.LABEL_NAME,
      dept_search_name: modalDepartmentsInfo.DEPT_SEARCH_NAME,
    };

    axios
      .put(
        `${fao_admin_api_root}/faoadmin/departments/${departmentsInfo.HIDE_DEPT_ID}`,
        message,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": fao_admin_api_key,
          },
        }
      )
      .then((response) => {
        if (!response.data.error) {
          setSubmitStatus({
            loading: false,
            error: false,
            message: "The department was successfully updated!",
          });
          refreshDepartments();
        } else {
          // Internal Error
          setSubmitStatus({
            loading: false,
            error: true,
            message:
              "There was an error updating the department: " +
              response.data.error,
          });
        }
      })
      .catch((error) => {
        setSubmitStatus({
          loading: false,
          error: true,
          message: "There was an error updating the department!",
        });
        console.log(error);
      });
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Edit Department"
      closeTimeoutMS={0}
    >
      <div className="modal-inner">
        <div className="modal-close-wrap">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={(event) => handleClose(event)}
          />
        </div>
        <div className="modal-heading">{modalAction} Department</div>
        <div className="modal-body-container">
          <form onSubmit={(event) => handleSubmit(event)}>
            <div className="form-group">
              <div className="form-label">Department ID:</div>
              <input
                type="text"
                required
                placeholder=""
                autoComplete="off"
                disabled
                value={modalDepartmentsInfo.HIDE_DEPT_ID}
              />
            </div>
            <div className="form-group">
              <div className="form-label">Department URL:</div>
              <input
                type="text"
                placeholder=""
                autoComplete="off"
                value={modalDepartmentsInfo.DV_URL || ""}
                onChange={(e) =>
                  setModalDepartmentsInfo({
                    ...modalDepartmentsInfo,
                    DV_URL: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <div className="form-label">Department Room Number:</div>
              <input
                type="text"
                placeholder=""
                autoComplete="off"
                value={modalDepartmentsInfo.DV_ROOM_NUMBER || ""}
                onChange={(e) =>
                  setModalDepartmentsInfo({
                    ...modalDepartmentsInfo,
                    DV_ROOM_NUMBER: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <div className="form-label">Department Phone:</div>
              <input
                type="text"
                placeholder=""
                autoComplete="off"
                value={modalDepartmentsInfo.DV_PHONE || ""}
                onChange={(e) =>
                  setModalDepartmentsInfo({
                    ...modalDepartmentsInfo,
                    DV_PHONE: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <div className="form-label">Department Location ID:</div>
              <input
                type="text"
                placeholder=""
                autoComplete="off"
                value={modalDepartmentsInfo.DV_LOCATION_ID || ""}
                onChange={(e) =>
                  setModalDepartmentsInfo({
                    ...modalDepartmentsInfo,
                    DV_LOCATION_ID: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <div className="form-label">Search Name:</div>
              <input
                type="text"
                placeholder=""
                autoComplete="off"
                value={modalDepartmentsInfo.SEARCH_NAME || ""}
                onChange={(e) =>
                  setModalDepartmentsInfo({
                    ...modalDepartmentsInfo,
                    SEARCH_NAME: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <div className="form-label">Label Name:</div>
              <input
                type="text"
                placeholder=""
                autoComplete="off"
                value={modalDepartmentsInfo.LABEL_NAME || ""}
                onChange={(e) =>
                  setModalDepartmentsInfo({
                    ...modalDepartmentsInfo,
                    LABEL_NAME: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <div className="form-label">Department Search Name:</div>
              <input
                type="text"
                placeholder=""
                autoComplete="off"
                value={modalDepartmentsInfo.DEPT_SEARCH_NAME || ""}
                onChange={(e) =>
                  setModalDepartmentsInfo({
                    ...modalDepartmentsInfo,
                    DEPT_SEARCH_NAME: e.target.value,
                  })
                }
              />
            </div>
            <div className="btn-group two">
              <button type="submit" className="btn-sm">
                Submit
                {submitStatus.loading && (
                  <span>
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      className="btn-processing fa-spin"
                    />
                  </span>
                )}
              </button>
              <button
                type="button"
                className="btn-sm secondary"
                onClick={(event) => handleClose(event)}
              >
                Close
              </button>
            </div>
            {submitStatus.message !== "" && (
              <div
                className={`submit-msg ${submitStatus.error ? "error" : ""}`}
              >
                {submitStatus.message}
              </div>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default DepartmentsModal;
