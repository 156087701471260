import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import SortTable from "../../utils/SortTable";
import Config from "../../config/Config";
import Loader from "../../utils/Loader";
import TitlesModal from "./TitlesModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

const columns = [
  {
    Header: "Title ID",
    accessor: "TITLE_ID",
    sticky: "left",
  },
  {
    Header: "Title Name",
    accessor: "TITLE_NAME",
  },
  {
    Header: "Active",
    accessor: "ACTIVE",
  },
  {
    Header: "Create Date",
    accessor: "CREATE_DATE",
    type: "date",
  },
  {
    Header: "Create User",
    accessor: "CREATE_USER",
  },
  {
    Header: "Modify Date",
    accessor: "MODIFY_DATE",
    type: "date",
  },
  {
    Header: "Modify User",
    accessor: "MODIFY_USER",
  },
];

const defaultSorted = [{ id: "TITLE_ID", desc: false }];

const TitlesAdmin = () => {
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [titles, setTitles] = useState([]);
  const [titleInfo, setTitleInfo] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(false);

  const handleRefreshTitles = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    axios
      .get(fao_admin_api_root + "/faoadmin/titles", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the titles: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setTitles(response.data.data);
          setAPIMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no titles.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAPIMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving titles!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key]);

  useEffect(() => {
    handleRefreshTitles();
  }, [handleRefreshTitles]);

  const handleAddClick = (event) => {
    setTitleInfo({});
    setModalAction("Add");
    setModalOpen(true);
  };

  const handleEditClick = (event, row) => {
    setTitleInfo(row.values);
    setModalAction("Edit");
    setModalOpen(true);
  };

  return (
    <div className="message-center-container">
      <div className="tool-title">FAO Titles Admin</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The FAO Titles Admin tool can be used to manage titles that are
          applied to faculty members.
        </div>
        <div className="tool-feature-list">
          <div className="tool-feature-label">Tool Features:</div>
          <ul>
            <li>Titles can be added or updated.</li>
            <li>
              The following fields can be modified for each title: Title Name,
              Active Indicator.
            </li>
            <li>
              Faculty Members can be managed via the{" "}
              <NavLink className="nav-link" to="/fao/faculty">
                FAO Faculty Admin
              </NavLink>{" "}
              tool.
            </li>
            <li>
              Title Types can be managed via the{" "}
              <NavLink className="nav-link" to="/fao/title/title-types">
                Title Types Admin
              </NavLink>{" "}
              tool.
            </li>
          </ul>
        </div>
      </div>
      <div className="table-container">
        <div className="btn-add-message-wrap">
          <button onClick={(event) => handleAddClick()}>
            <FontAwesomeIcon icon={faPlus} className="btn-add-message" />
            Add Title
          </button>
        </div>
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={titles}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              paginationEnabled={true}
              editClick={handleEditClick}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
      <TitlesModal
        modalOpen={modalOpen}
        modalAction={modalAction}
        closeModal={(event) => setModalOpen(false)}
        titleInfo={titleInfo}
        refreshTitles={handleRefreshTitles}
      />
    </div>
  );
};

export default TitlesAdmin;
