// https://engineering.upgrad.com/how-to-role-based-access-control-9294146dc6e3
import React, { useEffect } from "react";
import Can from "./Can";
import { Route } from "react-router-dom";
import { useUserContext } from "../context/UserContext";

const RouteWrapper = ({
  ComponentYes,
  ComponentNo,
  path,
  perform,
  exact,
  navHeader,
}) => {
  let { roles, actions } = useUserContext();
  let { setNavHeader } = actions;

  useEffect(() => {
    setNavHeader(navHeader);
  }, [navHeader, setNavHeader]);

  return roles ? (
    <Can
      roles={roles}
      perform={perform}
      yes={() => (
        <Route exact={exact && true} path={path} component={ComponentYes} />
      )}
      no={() => <Route path={path} component={ComponentNo} />}
    />
  ) : null;
};

export default RouteWrapper;
