import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import SortTable from "../../utils/SortTable";
import Config from "../../config/Config";
import Loader from "../../utils/Loader";
import PrivLocModal from "./PrivLocModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

const columns = [
  {
    Header: "Privilege Location",
    accessor: "PRIVILEGE_COMBO",
    sticky: "left",
  },
  {
    Header: "Privilege Location Code",
    accessor: "HIDE_PRIVILEGE_LOCATION_CODE",
  },
  {
    Header: "Privilege Abbrevation",
    accessor: "PRIVILEGE_ABBR",
  },
  {
    Header: "URL",
    accessor: "URL",
  },
  {
    Header: "Profile Display",
    accessor: "PROFILE_DISPLAY",
  },
  {
    Header: "Active Flag",
    accessor: "ACTIVE_FLAG",
  },
];

const defaultSorted = [{ id: "PRIVILEGE_COMBO", desc: false }];

const PrivLocAdmin = () => {
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [privLocs, setPrivLocs] = useState([]);
  const [privLocInfo, setPrivLocInfo] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(false);
  const [filterText, setFilterText] = useState("");

  const handleRefreshPrivLocs = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    const transformPrivLocs = (privLocs) =>
      privLocs.map((privLoc) => {
        return {
          ...privLoc,
          PRIVILEGE_COMBO: `${privLoc.PRIVILEGE_LOCATION} (${privLoc.PRIVILEGE_LOCATION_CODE})`,
          HIDE_PRIVILEGE_LOCATION_CODE: privLoc.PRIVILEGE_LOCATION_CODE,
        };
      });

    axios
      .get(fao_admin_api_root + "/faoadmin/privloc", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the privilege locations: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setPrivLocs(transformPrivLocs(response.data.data));
          setAPIMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no privilege locations.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAPIMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving privilege locations!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key]);

  useEffect(() => {
    handleRefreshPrivLocs();
  }, [handleRefreshPrivLocs]);

  const handleAddClick = (event) => {
    setPrivLocInfo({});
    setModalAction("Add");
    setModalOpen(true);
  };

  const handleEditClick = (event, row) => {
    setPrivLocInfo(row.values);
    setModalAction("Edit");
    setModalOpen(true);
  };

  const filterData = (data) => {
    return data.filter((dataElem) => {
      var bFound = false;
      columns.forEach((column) => {
        if (
          dataElem[column.accessor] &&
          dataElem[column.accessor]
            .toString()
            .toLowerCase()
            .includes(filterText.toLowerCase())
        )
          bFound = true;
      });
      return bFound;
    });
  };

  return (
    <div className="privilege-location-container">
      <div className="tool-title">FAO Privilege Locations Admin</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The FAO Privilege Locations Admin tool can be used to manage privilege
          locations that are associated with Faculty Affairs.
        </div>
        <div className="tool-feature-list">
          <div className="tool-feature-label">Tool Features:</div>
          <ul>
            <li>Privilege locations can be added, or updated.</li>
            <li>
              The following fields can be modified for each privilege location:
              privilege_location, privilege_abbr, inactive, url,
              profile_display.
            </li>
          </ul>
        </div>
      </div>
      <div className="filtering-container">
        <div className="filter-title">Filtering</div>
        <div className="filter-inputs-wrap one">
          <div className="input-icon-wrap">
            <div className="input-icon">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              value={filterText}
              type="text"
              placeholder="Search fields"
              autoComplete="off"
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
        </div>
        <button onClick={(event) => setFilterText("")}>Clear Filter</button>
      </div>
      <div className="table-container">
        <div className="btn-add-message-wrap">
          <button onClick={(event) => handleAddClick()}>
            <FontAwesomeIcon icon={faPlus} className="btn-add-message" />
            Add Privilege Location
          </button>
        </div>
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={filterData(privLocs)}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              editClick={handleEditClick}
              paginationEnabled={true}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
      <PrivLocModal
        modalOpen={modalOpen}
        modalAction={modalAction}
        closeModal={(event) => setModalOpen(false)}
        privLocInfo={privLocInfo}
        refreshPrivLocs={handleRefreshPrivLocs}
      />
    </div>
  );
};

export default PrivLocAdmin;
