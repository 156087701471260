import React from "react";
import { useUserContext } from "../context/UserContext";

const Index = () => {
  let { actions, isAuthenticated } = useUserContext();
  let { login, logout } = actions;

  return (
    <div className="main-content-outer">
      <div className="main-content-inner constrain-1260">
        <div className="hero-img index">
          <div className="hero-screen"></div>
          <div className="hero-img-title-wrap">
            <div className="hero-img-title">Universal Tooling</div>
            {isAuthenticated ? (
              <button onClick={logout}>Logout</button>
            ) : (
              <button onClick={login}>Login</button>
            )}
          </div>
        </div>
        <div className="index-content-container">
          <div className="tool-title">
            Welcome to the Universal Tooling Site
          </div>
          <div className="tool-description">
            The Universal Tooling site is a portfolio of tools for use by the
            FSM-IT developer team and its customers. The available tools are
            grouped by customer in the menu on the left. The tools are generally
            built using a microservices architecture, and are hosted in AWS.
            <br></br>
            <br></br>
            If you have any questions about the Universal Tooling site, or
            require access, please email us at{" "}
            <a className="mailto" href="mailto:mis-web-dev@northwestern.edu">
              mis-web-dev@northwestern.edu
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
