import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { faTimes, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Config from "../../config/Config";
import { useUserContext } from "../../context/UserContext";
import Loader from "../../utils/Loader";
import AccessType from "./AccessType";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "white",
    zIndex: "1",
    maxHeight: "90vh",
    overflow: "scroll",
    width: "400px",
    maxWidth: "calc(100% - 60px)",
  },
  overlay: {
    backgroundColor: "rgba(5, 7, 10, .8)",
    zIndex: "4001",
  },
};

const UsersSearchModal = ({
  modalOpen,
  modalAction,
  closeModal,
  userInfo,
  refreshUsers,
}) => {
  const [modalUserInfo, setModalUserInfo] = useState({});
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    error: false,
    message: "",
  });
  const [divisions, setDivisions] = useState([]);
  const [divisionsMessage, setDivisionsMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [accessTypes, setAccessTypes] = useState();
  const [accessTypesMessage, setAccessTypesMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [plans, setPlans] = useState();
  const [plansMessage, setPlansMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const context = useUserContext();

  let { netid } = context.userUT || {};

  useEffect(() => {
    if (userInfo.NETID) {
      axios
        .get(`${fao_admin_api_root}/faoadmin/users/${userInfo.NETID}`, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": fao_admin_api_key,
          },
        })
        .then((response) => {
          if (response.data.error) {
            // Internal Error
            setAPIMessage({
              loading: false,
              error: true,
              ready: false,
              messageText:
                "There was an error retrieving the user: " +
                response.data.error,
            });
          } else if (response.data.data.length > 0) {
            setModalUserInfo(response.data.data[0]);
            setAPIMessage({
              loading: false,
              error: false,
              ready: true,
              messageText: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "Error retrieving user!",
          });
        });
    } else {
      if (modalAction === "Add") {
        setModalUserInfo(userInfo);
        setAPIMessage({
          loading: false,
          error: false,
          ready: true,
          messageText: "",
        });
      }
    }
  }, [userInfo, fao_admin_api_root, fao_admin_api_key, modalAction]);

  useEffect(() => {
    axios
      .get(`${fao_admin_api_root}/faoadmin/users/accesstypes`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAccessTypesMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving access types: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setAccessTypes(response.data.data);
          setAccessTypesMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAccessTypesMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving access types!",
        });
      });

    axios
      .get(
        `${fao_admin_api_root}/faoadmin/users/divisions/fao?netid=${userInfo.NETID}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": fao_admin_api_key,
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setDivisionsMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving divisions: " + response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setDivisions(response.data.data);
          setDivisionsMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setDivisionsMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving divisions!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key, userInfo.NETID]);

  useEffect(() => {
    if (modalUserInfo.ACCESS && modalUserInfo.ACCESS["FAO Access"])
      axios
        .get(
          `${fao_admin_api_root}/faoadmin/users/divisions/plan?netid=${
            userInfo.NETID ? userInfo.NETID : modalUserInfo.NETID
          }&divisions=${modalUserInfo.ACCESS["FAO Access"]}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": fao_admin_api_key,
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            // Internal Error
            setPlansMessage({
              loading: false,
              error: true,
              ready: false,
              messageText:
                "There was an error retrieving plans: " + response.data.error,
            });
          } else if (response.data.data.length > 0) {
            setPlans(response.data.data);
            setPlansMessage({
              loading: false,
              error: false,
              ready: true,
              messageText: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setPlansMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "Error retrieving plans!",
          });
        });
  }, [
    fao_admin_api_root,
    fao_admin_api_key,
    modalUserInfo.ACCESS,
    userInfo.NETID,
    modalUserInfo.NETID,
  ]);

  const handleClose = (event) => {
    event.preventDefault();
    setModalUserInfo({});
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });
    setSubmitStatus({
      loading: false,
      error: false,
      message: "",
    });
    closeModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitStatus({ loading: true });

    if (modalAction === "Add") {
      if (
        !modalUserInfo.ACCESS["FAO Access"] &&
        !modalUserInfo.ACCESS["View Access"]
      ) {
        alert("Please add access to an access type.");
        setSubmitStatus({
          loading: false,
          error: true,
          message: "Access is required for one access type!",
        });
      } else {
        let message = {
          create_user: netid,
          netid: modalUserInfo.NETID,
          lastname: modalUserInfo.LAST_NAME,
          firstname: modalUserInfo.FIRST_NAME,
          email: modalUserInfo.EMAIL,
          phone: modalUserInfo.PHONE,
          dept: modalUserInfo.ORG_UNIT,
          reason: modalUserInfo.REASON,
          notes: modalUserInfo.NOTES,
          fao_access:
            modalUserInfo.ACCESS["FAO Access"] &&
            modalUserInfo.ACCESS["FAO Access"].split(",").map((accessElem) => {
              return { division_id: accessElem };
            }),
          view_access:
            modalUserInfo.ACCESS["View Access"] &&
            modalUserInfo.ACCESS["View Access"].split(",").map((accessElem) => {
              return { access_code: accessElem };
            }),
          plan_access:
            modalUserInfo.ACCESS["Plan Access"] &&
            modalUserInfo.ACCESS["Plan Access"].split(",").map((accessElem) => {
              return { division_id: accessElem };
            }),
        };

        axios
          .post(`${fao_admin_api_root}/faoadmin/users/search`, message, {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": fao_admin_api_key,
            },
          })
          .then((response) => {
            if (!response.data.error) {
              setSubmitStatus({
                loading: false,
                error: false,
                message: "The user was successfully added!",
              });
              refreshUsers();
            } else {
              // Internal Error
              setSubmitStatus({
                loading: false,
                error: true,
                message:
                  "There was an error adding the user: " + response.data.error,
              });
            }
          })
          .catch((error) => {
            setSubmitStatus({
              loading: false,
              error: true,
              message: "There was an error adding the user!",
            });
            console.log(error);
          });
      }
    } else if (modalAction === "Edit") {
      let message = {
        modify_user: netid,
        lastname: modalUserInfo.LAST_NAME,
        firstname: modalUserInfo.FIRST_NAME,
        email: modalUserInfo.EMAIL,
        phone: modalUserInfo.PHONE,
        reason: modalUserInfo.REASON,
        notes: modalUserInfo.NOTES,
        org_unit: modalUserInfo.ORG_UNIT,
        fao_access:
          modalUserInfo.ACCESS["FAO Access"] &&
          modalUserInfo.ACCESS["FAO Access"].split(",").map((accessElem) => {
            return { division_id: accessElem };
          }),
        view_access:
          modalUserInfo.ACCESS["View Access"] &&
          modalUserInfo.ACCESS["View Access"].split(",").map((accessElem) => {
            return { access_code: accessElem };
          }),
        plan_access:
          modalUserInfo.ACCESS["Plan Access"] &&
          modalUserInfo.ACCESS["Plan Access"].split(",").map((accessElem) => {
            return { division_id: accessElem };
          }),
      };

      axios
        .put(
          `${fao_admin_api_root}/faoadmin/users/${userInfo.NETID}`,
          message,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": fao_admin_api_key,
            },
          }
        )
        .then((response) => {
          if (!response.data.error) {
            setSubmitStatus({
              loading: false,
              error: false,
              message: "The user was successfully updated!",
            });
            refreshUsers();
          } else {
            // Internal Error
            setSubmitStatus({
              loading: false,
              error: true,
              message:
                "There was an error updating the user: " + response.data.error,
            });
          }
        })
        .catch((error) => {
          setSubmitStatus({
            loading: false,
            error: true,
            message: "There was an error updating the user!",
          });
          console.log(error);
        });
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Edit User"
      closeTimeoutMS={0}
    >
      <div className="modal-inner">
        <div className="modal-close-wrap">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={(event) => handleClose(event)}
          />
        </div>
        <div className="modal-heading">{modalAction} User</div>
        {apiMessage.ready ? (
          <div className="modal-body-container">
            <form onSubmit={(event) => handleSubmit(event)}>
              {userInfo.NETID ? (
                <div className="form-group">
                  <div className="form-label">NetID:</div>
                  <input
                    type="text"
                    required
                    placeholder=""
                    autoComplete="off"
                    disabled
                    value={userInfo.NETID}
                  />
                </div>
              ) : (
                <div className="form-group">
                  <div className="form-label">NetID:</div>
                  <input
                    type="text"
                    required
                    placeholder="Enter NetID"
                    autoComplete="off"
                    value={modalUserInfo.NETID || ""}
                    onChange={(e) =>
                      setModalUserInfo({
                        ...modalUserInfo,
                        NETID: e.target.value,
                      })
                    }
                  />
                </div>
              )}
              <div className="form-group">
                <div className="form-label">First Name:</div>
                <input
                  type="text"
                  required
                  placeholder="Enter first name"
                  autoComplete="off"
                  value={modalUserInfo.FIRST_NAME || ""}
                  onChange={(e) =>
                    setModalUserInfo({
                      ...modalUserInfo,
                      FIRST_NAME: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <div className="form-label">Last Name:</div>
                <input
                  type="text"
                  required
                  placeholder="Enter last name"
                  autoComplete="off"
                  value={modalUserInfo.LAST_NAME || ""}
                  onChange={(e) =>
                    setModalUserInfo({
                      ...modalUserInfo,
                      LAST_NAME: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <div className="form-label">Email:</div>
                <input
                  type="email"
                  required
                  placeholder="Enter email"
                  autoComplete="off"
                  value={modalUserInfo.EMAIL || ""}
                  onChange={(e) =>
                    setModalUserInfo({
                      ...modalUserInfo,
                      EMAIL: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <div className="form-label">Organization:</div>
                <input
                  type="text"
                  required
                  placeholder="Enter organization"
                  autoComplete="off"
                  value={modalUserInfo.ORG_UNIT || ""}
                  onChange={(e) =>
                    setModalUserInfo({
                      ...modalUserInfo,
                      ORG_UNIT: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <div className="form-label">Phone:</div>
                <input
                  type="text"
                  placeholder="Enter phone number"
                  autoComplete="off"
                  value={modalUserInfo.PHONE || ""}
                  onChange={(e) =>
                    setModalUserInfo({
                      ...modalUserInfo,
                      PHONE: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <div className="form-label">
                  Describe Role and Reason for Access:
                </div>
                <textarea
                  value={modalUserInfo.REASON || ""}
                  onChange={(e) =>
                    setModalUserInfo({
                      ...modalUserInfo,
                      REASON: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <div className="form-label">
                  Notes Regarding Type of Access Granted:
                </div>
                <textarea
                  value={modalUserInfo.NOTES || ""}
                  onChange={(e) =>
                    setModalUserInfo({
                      ...modalUserInfo,
                      NOTES: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <div className="form-label">Access Types:</div>
                {divisionsMessage.ready ? (
                  <AccessType
                    accessTypes={divisions}
                    accessTypeValue="DIVISION_ID"
                    accessTypeDispValue="DV_ABBR"
                    strAccessType="FAO Access"
                    accessTypeLabel="FAO Access"
                    modalUserInfo={modalUserInfo}
                    setModalUserInfo={setModalUserInfo}
                    noAccessMessage="There is currently no FAO Access for this user."
                  />
                ) : (
                  divisionsMessage.messageText
                )}
                {plans &&
                  (plansMessage.ready ? (
                    <AccessType
                      accessTypes={plans}
                      accessTypeValue="DIVISION_ID"
                      accessTypeDispValue="DV_ABBR"
                      strAccessType="Plan Access"
                      accessTypeLabel="Plan Access"
                      modalUserInfo={modalUserInfo}
                      setModalUserInfo={setModalUserInfo}
                      noAccessMessage="There is currently no Plan Access for this user."
                    />
                  ) : (
                    plansMessage.messageText
                  ))}
                {accessTypesMessage.ready ? (
                  <AccessType
                    accessTypes={accessTypes}
                    accessTypeValue="CODE"
                    accessTypeDispValue="DESCRIPTION"
                    strAccessType="View Access"
                    accessTypeLabel="View Access"
                    modalUserInfo={modalUserInfo}
                    setModalUserInfo={setModalUserInfo}
                    noAccessMessage="There is currently no View Access for this user."
                  />
                ) : (
                  accessTypesMessage.messageText
                )}
              </div>
              <div className="btn-group two">
                <button type="submit" className="btn-sm">
                  Submit
                  {submitStatus.loading && (
                    <span>
                      <FontAwesomeIcon
                        icon={faCircleNotch}
                        className="btn-processing fa-spin"
                      />
                    </span>
                  )}
                </button>
                <button
                  type="button"
                  className="btn-sm secondary"
                  onClick={(event) => handleClose(event)}
                >
                  Close
                </button>
              </div>
              {submitStatus.message !== "" && (
                <div
                  className={`submit-msg ${submitStatus.error ? "error" : ""}`}
                >
                  {submitStatus.message}
                </div>
              )}
            </form>
          </div>
        ) : (
          <Loader message={apiMessage} />
        )}
      </div>
    </Modal>
  );
};

export default UsersSearchModal;
