import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { faTimes, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MessageTypes } from "../config/MessageCenter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Config from "../config/Config";
import { useUserContext } from "../context/UserContext";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "white",
    zIndex: "1",
    maxHeight: "100vh",
    overflow: "scroll",
    width: "400px",
    maxWidth: "calc(100% - 60px)",
  },
  overlay: {
    backgroundColor: "rgba(5, 7, 10, .8)",
    zIndex: "4001",
  },
};

const MessageCenterModal = ({
  modalOpen,
  closeModal,
  action,
  messageInfo,
  refreshMessages,
}) => {
  const [formMode, setFormMode] = useState("locked");
  const [modalMessage, setModalMessage] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [messageType, setMessageType] = useState("warning");
  let { mc_api_root, mc_api_key } = Config;
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    error: false,
    message: "",
  });
  let { accessToken } = useUserContext();

  useEffect(() => {
    if (messageInfo.messageType) setMessageType(messageInfo.messageType);
    if (messageInfo.message) setModalMessage(messageInfo.message);
    if (messageInfo.startDate) setStartDate(new Date(messageInfo.startDate));
    if (messageInfo.endDate) setEndDate(new Date(messageInfo.endDate));
  }, [messageInfo]);

  const handleSubmit = (event) => {
    setSubmitStatus({ loading: true });

    let headers = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": mc_api_key,
        "access-token": accessToken,
      },
    };

    let message = {
      messageType: messageType,
      message: modalMessage,
      startDate: startDate,
      endDate: endDate,
      userName: "universal-tool",
    };

    if (action === "Edit") {
      axios
        .put(
          mc_api_root + "/message/" + messageInfo.messageID,
          message,
          headers
        )
        .then((response) => {
          if (!response.data.error) {
            setSubmitStatus({
              loading: false,
              error: false,
              message: "The message was successfully updated!",
            });
            refreshMessages();
          } else {
            // Internal Error
            setSubmitStatus({
              loading: false,
              error: true,
              message:
                "There was an error saving the message: " + response.data.error,
            });
          }
        })
        .catch((error) => {
          setSubmitStatus({
            loading: false,
            error: true,
            message: "There was an error saving the message!",
          });
          console.log(error);
        });
    } else if (action === "Add") {
      axios
        .post(mc_api_root + "/message", message, headers)
        .then((response) => {
          if (!response.data.error) {
            setSubmitStatus({
              loading: false,
              error: false,
              message: "The message was successfully added!",
            });
            refreshMessages();
          } else {
            // Internal Error
            setSubmitStatus({
              loading: false,
              error: true,
              message:
                "There was an error adding the message: " + response.data.error,
            });
          }
        })
        .catch((error) => {
          setSubmitStatus({
            loading: false,
            error: true,
            message: "There was an error adding the message!",
          });
          console.log(error);
        });
    } else {
      setSubmitStatus({
        loading: false,
        error: true,
        message: "Invalid action: " + action,
      });
      console.log("Invalid action: " + action);
    }
  };

  const handleClose = () => {
    setFormMode("locked");
    setModalMessage("");
    setStartDate(new Date());
    setEndDate(new Date());
    setMessageType("warning");
    setSubmitStatus({
      loading: false,
      error: false,
      message: "",
    });
    closeModal();
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={`${action} Message`}
      closeTimeoutMS={0}
    >
      <div className="modal-inner">
        <div className="modal-close-wrap">
          <FontAwesomeIcon icon={faTimes} onClick={(event) => handleClose()} />
        </div>
        <div className="modal-heading">{action} Message</div>
        <div className="modal-body-container">
          <div className="form-group">
            <div className="form-label">Message:</div>
            <textarea
              name="banner-message"
              value={modalMessage}
              disabled={formMode === "locked" ? true : false}
              onChange={(event) => setModalMessage(event.target.value)}
            ></textarea>
            {formMode === "locked" ? (
              <div className="btn-group">
                <button
                  className="btn-sm"
                  onClick={(event) => setFormMode("edit")}
                >
                  Edit
                </button>
              </div>
            ) : (
              <div className="btn-group two">
                <button
                  className="btn-sm"
                  onClick={(event) => {
                    setFormMode("locked");
                  }}
                >
                  Save
                </button>
                <button
                  className="btn-sm secondary"
                  onClick={(event) => {
                    setModalMessage(messageInfo.message);
                    setFormMode("locked");
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          <div className="form-group">
            <div className="form-label">Message Type:</div>
            <div className="custom-dd-wrap">
              <select
                defaultValue={messageType}
                onChange={(event) => setMessageType(event.target.value)}
              >
                {MessageTypes.map((_messageType) => {
                  return (
                    <option value={_messageType.value} key={_messageType.value}>
                      {_messageType.dispValue}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="bg-color-wrap">
              Background Color:{" "}
              <div className={`bg-color-preview color-${messageType}`}></div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-label">Start Date:</div>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showTimeSelect
              dateFormat="Pp"
            />
          </div>
          <div className="form-group">
            <div className="form-label">End Date:</div>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              showTimeSelect
              dateFormat="Pp"
            />
          </div>
          <div className="btn-group two">
            <button className="btn-sm" onClick={(event) => handleSubmit()}>
              Submit
              {submitStatus.loading ? (
                <span>
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    className="btn-processing fa-spin"
                  />
                </span>
              ) : null}
            </button>
            <button
              className="btn-sm secondary"
              onClick={(event) => handleClose()}
            >
              Close
            </button>
          </div>
          {submitStatus.message !== "" ? (
            <div className={`submit-msg ${submitStatus.error ? "error" : ""}`}>
              {submitStatus.message}
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default MessageCenterModal;
