import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import SortTable from "../../utils/SortTable";
import Config from "../../config/Config";
import Loader from "../../utils/Loader";
import DivisionsModal from "./DivisionsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const columns = [
  {
    Header: "Division ID",
    accessor: "DIVISION_ID",
    sticky: "left",
  },
  {
    Header: "Division Name",
    accessor: "DV_NAME",
  },
  {
    Header: "Department ID",
    accessor: "DEPT_ID",
  },
  {
    Header: "Division URL",
    accessor: "DV_URL",
  },
  {
    Header: "Division Room Number",
    accessor: "DV_ROOM_NUMBER",
  },
  {
    Header: "Division Phone",
    accessor: "DV_PHONE",
  },
  {
    Header: "Division Location ID",
    accessor: "DV_LOCATION_ID",
  },
  {
    Header: "Building",
    accessor: "BUILDING",
  },
  {
    Header: "Street Address",
    accessor: "STREET_ADDRESS",
  },
  {
    Header: "City",
    accessor: "CITY",
  },
  {
    Header: "State",
    accessor: "STATE",
  },
  {
    Header: "Zip",
    accessor: "ZIP",
  },
  {
    Header: "Campus Name",
    accessor: "CAMPUS_NAME_CSZ",
  },
  {
    Header: "Division Abbreviation",
    accessor: "DV_ABBR",
  },
  {
    Header: "Search Name",
    accessor: "SEARCH_NAME",
  },
  {
    Header: "Label Name",
    accessor: "LABEL_NAME",
  },
  {
    Header: "Department Search Name",
    accessor: "DEPT_SEARCH_NAME",
  },
];

const defaultSorted = [{ id: "DIVISION_ID", desc: false }];

const DivisionsAdmin = () => {
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [divisions, setDivisions] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [buildingsMessage, setBuildingsMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [divisionsInfo, setDivisionsInfo] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(false);
  const [filterText, setFilterText] = useState("");

  const handleRefreshDivisions = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    axios
      .get(fao_admin_api_root + "/faoadmin/divisions", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the divisions: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setDivisions(response.data.data);
          setAPIMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no divisions.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAPIMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving divisions!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key]);

  useEffect(() => {
    axios
      .get(fao_admin_api_root + "/faoadmin/buildings", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the buildings: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setBuildings(response.data.data);
          setBuildingsMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setBuildingsMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no buildings.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setBuildingsMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving buildings!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key]);

  useEffect(() => {
    handleRefreshDivisions();
  }, [handleRefreshDivisions]);

  const handleEditClick = (event, row) => {
    setDivisionsInfo(row.values);
    setModalAction("Edit");
    setModalOpen(true);
  };

  const filterData = (data) => {
    return data.filter((dataElem) => {
      var bFound = false;
      columns.forEach((column) => {
        if (
          dataElem[column.accessor] &&
          dataElem[column.accessor]
            .toString()
            .toLowerCase()
            .includes(filterText.toLowerCase())
        )
          bFound = true;
      });
      return bFound;
    });
  };

  return (
    <div className="message-center-container">
      <div className="tool-title">FAO Divisions Admin</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The FAO Divisions Admin tool can be used to manage divisions that are
          associated with Faculty Affairs.
        </div>
        <div className="tool-feature-list">
          <div className="tool-feature-label">Tool Features:</div>
          <ul>
            <li>
              The following fields can be modified for each division: Division
              URL, Division Room Number, Division Phone, Division Location ID
              (Building), Search Name, Label Name, Department Search Name.
            </li>
          </ul>
        </div>
      </div>
      <div className="filtering-container">
        <div className="filter-title">Filtering</div>
        <div className="filter-inputs-wrap one">
          <div className="input-icon-wrap">
            <div className="input-icon">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              value={filterText}
              type="text"
              placeholder="Search fields"
              autoComplete="off"
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
        </div>
        <button onClick={(event) => setFilterText("")}>Clear Filter</button>
      </div>
      <div className="table-container">
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={filterData(divisions)}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              paginationEnabled={true}
              editClick={handleEditClick}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
      <DivisionsModal
        modalOpen={modalOpen}
        modalAction={modalAction}
        closeModal={(event) => setModalOpen(false)}
        divisionsInfo={divisionsInfo}
        refreshDivisions={handleRefreshDivisions}
        buildings={buildings}
        buildingsMessage={buildingsMessage}
      />
    </div>
  );
};

export default DivisionsAdmin;
