import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import Can from "../auth/Can";
import { useUserContext } from "../context/UserContext";

const MenuSubnav = ({ expand, expandToggle, app, setOpenMenu }) => {
  const userContext = useUserContext();
  let { roles } = userContext;

  // TODO: use a component for MenuNavItem without losing animation

  return (
    <>
      {app.security ? (
        roles && (
          <Can
            roles={roles}
            perform={app.security}
            yes={() => (
              <div className={`menu-nav-item-wrap ${expand[app.name]}`}>
                <div
                  className="menu-nav-label"
                  onClick={(event) => expandToggle(event, app.name)}
                >
                  {app.dispName}{" "}
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className="btn-menu-expand"
                  />
                </div>
                <div className="menu-nav-rollup-container">
                  {app.subNavs
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((subNav) => {
                      return (
                        <div
                          className="menu-subnav-item-wrap"
                          key={subNav.name}
                        >
                          <NavLink
                            to={`/${app.name}/${subNav.route}`}
                            onClick={(event) => setOpenMenu(false)}
                          >
                            <div className="menu-subnav-label">
                              {subNav.dispName}
                            </div>
                          </NavLink>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            no={() => null}
          />
        )
      ) : (
        <div className={`menu-nav-item-wrap ${expand[app.name]}`}>
          <div
            className="menu-nav-label"
            onClick={(event) => expandToggle(event, app.name)}
          >
            {app.dispName}{" "}
            <FontAwesomeIcon icon={faAngleDown} className="btn-menu-expand" />
          </div>
          <div className="menu-nav-rollup-container">
            {app.subNavs.map((subNav) => {
              return (
                <div className="menu-subnav-item-wrap" key={subNav.name}>
                  <NavLink
                    to={`/${app.name}/${subNav.route}`}
                    onClick={(event) => setOpenMenu(false)}
                  >
                    <div className="menu-subnav-label">{subNav.dispName}</div>
                  </NavLink>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default MenuSubnav;
