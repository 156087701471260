export const NavMenu = [
  {
    name: "awome",
    dispName: "AWOME",
    security: "awome-pages:visit",
    subNavs: [
      {
        name: "focus",
        dispName: "Focus",
        route: "focus",
      },
      {
        name: "emerg",
        dispName: "eMerg",
        route: "emerg",
      },
    ],
  },
  {
    name: "fao",
    dispName: "Faculty Affairs",
    security: "fao-pages:visit",
    subNavs: [
      {
        name: "faculty",
        dispName: "Faculty",
        route: "faculty",
      },
      {
        name: "titles",
        dispName: "Titles",
        route: "titles",
      },
      {
        name: "professorships",
        dispName: "Professorships",
        route: "professorships",
      },
      {
        name: "buildings",
        dispName: "Buildings",
        route: "buildings",
      },
      {
        name: "departments",
        dispName: "Departments",
        route: "departments",
      },
      {
        name: "divisions",
        dispName: "Divisions",
        route: "divisions",
      },
      {
        name: "practice-plans",
        dispName: "Practice Plans",
        route: "practice-plans",
      },
      {
        name: "privloc",
        dispName: "Privilege Locations",
        route: "privloc",
      },
      {
        name: "hsc-sponsors",
        dispName: "HSC Sponsors",
        route: "hsc-sponsors",
      },
      {
        name: "users-search",
        dispName: "Users Search",
        route: "users-search",
      },
      {
        name: "missing-data",
        dispName: "Missing Data",
        route: "missing-data",
      },
    ],
  },
];

export const Roles = [
  {
    name: "sysadmin",
    dispName: "System Administrator",
  },
  {
    name: "awome",
    dispName: "AWOME",
  },
  {
    name: "fao",
    dispName: "Faculty Affairs",
  },
  {
    name: "new-role",
    dispName: "New Role",
  },
];

export const Tools = {
  admin: [
    {
      name: "user-management",
      dispName: "User Management",
      route: "user-management",
      default: true,
    },
    {
      name: "portal-reports-management",
      dispName: "Portal Reports Management",
      route: "portal-reports-management",
    },
    {
      name: "db-manifest-management",
      dispName: "DB Manifest Management",
      route: "db-manifest-management",
    },
  ],
  emerg: [
    {
      name: "message-center",
      dispName: "Message Center",
      route: "message-center",
      default: true,
    },
  ],
  focus: [
    {
      name: "message-center",
      dispName: "Message Center",
      route: "message-center",
      default: true,
    },
  ],
  faoFaculty: [
    {
      name: "faculty",
      dispName: "Faculty",
      route: "faculty",
      default: true,
    },
    {
      name: "faculty-note-types",
      dispName: "Note Types",
      route: "note-types",
    },
  ],
  faoTitles: [
    {
      name: "titles",
      dispName: "Titles",
      route: "titles",
      default: true,
    },
    {
      name: "title-types",
      dispName: "Title Types",
      route: "title-types",
    },
    {
      name: "title-groups",
      dispName: "Title Groups",
      route: "title-groups",
    },
  ],
  faoProfessorships: [
    {
      name: "professorships",
      dispName: "Professorships",
      route: "professorships",
      default: true,
    },
    {
      name: "professorships-note-types",
      dispName: "Note Types",
      route: "note-types",
    },
    {
      name: "professorships-holders",
      dispName: "Holders",
      route: "holders",
    },
  ],
  faoBuildings: [
    {
      name: "buildings",
      dispName: "Buildings",
      route: "buildings",
      default: true,
    },
  ],
  faoDepartments: [
    {
      name: "departments",
      dispName: "Departments",
      route: "departments",
      default: true,
    },
  ],
  faoDivisions: [
    {
      name: "divisions",
      dispName: "Divisions",
      route: "divisions",
      default: true,
    },
  ],
  faoPracticePlans: [
    {
      name: "practice-plans",
      dispName: "Practice Plans",
      route: "practice-plans",
      default: true,
    },
  ],
  faoPrivLoc: [
    {
      name: "privloc",
      dispName: "Privilege Locations",
      route: "privloc",
      default: true,
    },
  ],
  faoHSCSponsors: [
    {
      name: "hsc-sponsors",
      dispName: "HSC Sponsors",
      route: "hsc-sponsors",
      default: true,
    },
  ],
  faoUsersSearch: [
    {
      name: "users-search",
      dispName: "Users Search",
      route: "users-search",
      default: true,
    },
  ],
  faoMissingData: [
    {
      name: "missing-data",
      dispName: "Missing Data",
      route: "missing-data",
      default: true,
    },
  ],
};
