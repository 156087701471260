import React from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faEdit,
  faTrashAlt,
  faStepBackward,
  faStepForward,
  faFastBackward,
  faFastForward,
} from "@fortawesome/free-solid-svg-icons";
import { useSticky } from "react-table-sticky";
import { CSVLink } from "react-csv";

const evalCellType = (cell) => {
  switch (cell.column.type) {
    case "date": {
      if (cell.value) return convertDate(cell.value);
      else return null;
    }
    case "role-list": {
      return (
        <HtmlCell
          html={cell.value
            .map(
              (role) =>
                `<div class="role-cell-wrap">
              <div class="role-cell-app-name">
                ${role.ROLE_ID} -- ${role.ROLE_NAME}
              </div>
            </div>`
            )
            .join("")}
        />
      );
    }
    case "role-json":
      let roleMarkup = "";
      cell.value.forEach((role) => {
        roleMarkup += `<div class="role-cell-wrap">
                        <div class="role-cell-app-name">${role}</div>
                      </div>`;
      });
      return <HtmlCell html={roleMarkup} />;
    case "json":
      return JSON.stringify(cell.value);
    case "boolean":
      return cell.value.toString();
    case "html":
      return <HtmlCell html={cell.value} />;
    case "nowrap":
      return (
        <HtmlCell
          html={`<span style="white-space: nowrap;">${cell.value}</span>`}
        />
      );
    default:
      return cell.render("Cell");
  }
};

const convertDate = (dateString) => {
  var date = new Date(dateString);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hour = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();

  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;
  if (hour < 10) hour = "0" + hour;
  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;

  let roleMarkup =
    year +
    "-" +
    month +
    "-" +
    day +
    "<br>" +
    hour +
    ":" +
    minutes +
    ":" +
    seconds;

  return <HtmlCell html={roleMarkup} />;
};

const createMarkup = (markup) => {
  return { __html: markup.html };
};

const HtmlCell = (html) => {
  return <div dangerouslySetInnerHTML={createMarkup(html)} />;
};

function Table({
  columns,
  data,
  defaultSorted,
  editClick,
  deleteClick,
  paginationEnabled,
  exportEnabled,
  dataChanged,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    state: { pageIndex },
  } = useTable(
    {
      autoResetPage: dataChanged,
      autoResetSortBy: false,
      columns,
      data,
      initialState: {
        sortBy: defaultSorted,
        pageSize: 10,
        pageIndex: 0,
        hiddenColumns: [
          "HIDE_DEPT_NAME",
          "HIDE_DEPT_ID",
          "HIDE_PRIVILEGE_LOCATION_CODE",
        ],
      },
    },
    useSortBy,
    useSticky,
    usePagination,
    (hooks) => {
      if (editClick) {
        hooks.visibleColumns.push((columns) => [
          ...columns,
          {
            id: "edit",
            Header: <div></div>,
            Cell: ({ row }) => (
              <>
                <div
                  className="ut-table-action-icon-wrap"
                  onClick={(event) => editClick(event, row)}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="ut-table-icon-edit"
                  />
                </div>
                {deleteClick ? (
                  <div
                    className="ut-table-action-icon-wrap"
                    onClick={(event) => deleteClick(event, row)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </div>
                ) : null}
              </>
            ),
          },
        ]);
      }
    }
  );
  return (
    <>
      {exportEnabled && (
        <div className="export-container">
          <CSVLink data={data} filename={exportEnabled.filename}>
            <button>Export to CSV</button>
          </CSVLink>
        </div>
      )}
      <div className="sort-table-wrap">
        <table {...getTableProps()} className="ut-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="ut-table-header-group"
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`ut-table-header ${
                      column.isSorted ? "sorted" : ""
                    } ${index === 0 ? "sticky" : ""}`}
                  >
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          className="ut-table-sort-icon"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faAngleUp}
                          className="ut-table-sort-icon"
                        />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {paginationEnabled
              ? page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="ut-table-row">
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`ut-table-cell ${
                              cell.column.isSorted ? "sorted" : ""
                            } ${index === 0 ? "sticky" : ""} ${
                              cell.column.type ? cell.column.type : ""
                            }`}
                          >
                            {evalCellType(cell)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              : rows.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="ut-table-row">
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`ut-table-cell ${
                              cell.column.isSorted ? "sorted" : ""
                            } ${index === 0 ? "sticky" : ""} ${
                              cell.column.type ? cell.column.type : ""
                            }`}
                          >
                            {evalCellType(cell)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
      {paginationEnabled && (
        <div className="pagination-container">
          <div className="pagination-actions-wrap">
            <div
              className={`ut-table-pagination-icon-wrap ${
                !canPreviousPage ? "disabled" : ""
              }`}
              onClick={(event) => gotoPage(0)}
            >
              <FontAwesomeIcon icon={faFastBackward} />
            </div>
            <div
              className={`ut-table-pagination-icon-wrap ${
                !canPreviousPage ? "disabled" : ""
              }`}
              onClick={(event) => previousPage()}
            >
              <FontAwesomeIcon icon={faStepBackward} />
            </div>
            <div
              className={`ut-table-pagination-icon-wrap ${
                !canNextPage ? "disabled" : ""
              }`}
              onClick={(event) => nextPage()}
            >
              <FontAwesomeIcon icon={faStepForward} />
            </div>
            <div
              className={`ut-table-pagination-icon-wrap ${
                !canNextPage ? "disabled" : ""
              }`}
              onClick={(event) => gotoPage(pageCount - 1)}
            >
              <FontAwesomeIcon icon={faFastForward} />
            </div>
          </div>
          <div className="pagination-summary">
            Page {pageIndex + 1} of {pageOptions.length}
          </div>
        </div>
      )}
    </>
  );
}

const SortTable = ({
  sortColumns,
  sortData,
  sortDefaultSorted,
  editClick,
  deleteClick,
  paginationEnabled,
  exportEnabled,
  dataChanged,
}) => {
  return (
    <Table
      data={sortData}
      columns={sortColumns}
      defaultSorted={sortDefaultSorted}
      editClick={editClick}
      deleteClick={deleteClick}
      paginationEnabled={paginationEnabled}
      exportEnabled={exportEnabled}
      dataChanged={dataChanged}
    />
  );
};

export default SortTable;
