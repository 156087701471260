import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import SortTable from "../../utils/SortTable";
import Config from "../../config/Config";
import Loader from "../../utils/Loader";
import ProfessorshipsModal from "./ProfessorshipsHoldersModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

const columns = [
  {
    Header: "ID",
    accessor: "ID",
    sticky: "left",
  },
  {
    Header: "Professorship ID",
    accessor: "PROF_ID",
  },
  {
    Header: "Professorship Name",
    accessor: "PROFESSORSHIP_NAME",
  },
  {
    Header: "Employee ID",
    accessor: "EMPLOYEE_ID",
  },
  {
    Header: "First Name",
    accessor: "FIRST_NAME",
  },
  {
    Header: "Last Name",
    accessor: "LAST_NAME",
  },
  {
    Header: "Start Date",
    accessor: "START_DATE",
    type: "date",
  },
  {
    Header: "End Date",
    accessor: "END_DATE",
    type: "date",
  },
  {
    Header: "Display Flag",
    accessor: "DISPLAY_FLAG",
  },
  {
    Header: "Current Flag",
    accessor: "CURRENT_FLAG",
  },
  {
    Header: "Coterminous",
    accessor: "COTERMINOUS",
  },
  {
    Header: "Reappointment Eligibilty",
    accessor: "REAPPT_ELIG",
  },
];

const defaultSorted = [{ id: "ID", desc: false }];

const ProfessorshipHoldersAdmin = () => {
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [holders, setHolders] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(false);
  const [holderInfo, setHolderInfo] = useState({});
  const [filterText, setFilterText] = useState("");

  const handleRefreshHolders = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    axios
      .get(fao_admin_api_root + "/faoadmin/professorships/holders", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the professorships holders: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setHolders(response.data.data);
          setAPIMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no professorships holders.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAPIMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving professorships holders!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key]);

  useEffect(() => {
    handleRefreshHolders();
  }, [handleRefreshHolders]);

  const handleAddClick = (event) => {
    setHolderInfo({});
    setModalAction("Add");
    setModalOpen(true);
  };

  const handleEditClick = (event, row) => {
    setHolderInfo(row.values);
    setModalAction("Edit");
    setModalOpen(true);
  };

  const filterData = (data) => {
    return data.filter((dataElem) => {
      var bFound = false;
      columns.forEach((column) => {
        if (
          dataElem[column.accessor] &&
          dataElem[column.accessor]
            .toString()
            .toLowerCase()
            .includes(filterText.toLowerCase())
        )
          bFound = true;
      });
      return bFound;
    });
  };

  return (
    <div className="professorship-admin-container">
      <div className="tool-title">Professorships Holders Admin</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The Professorships Holders Admin tool can be used to perform various
          tasks on professorship holders.
        </div>
        <div className="tool-feature-list">
          <div className="tool-feature-label">Tool Features:</div>
          <ul>
            <li>Professorship holders can be added and modified.</li>
            <li>
              The following fields can be modified for each professorship
              holder: prof_id, display_flag, current_flag, coterminous,
              reappt_elig, start_date, end_date.
            </li>
          </ul>
        </div>
      </div>
      <div className="filtering-container">
        <div className="filter-title">Filtering</div>
        <div className="filter-inputs-wrap one">
          <div className="input-icon-wrap">
            <div className="input-icon">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              value={filterText}
              type="text"
              placeholder="Search fields"
              autoComplete="off"
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
        </div>
        <button onClick={(event) => setFilterText("")}>Clear Filter</button>
      </div>
      <div className="table-container">
        <div className="btn-add-message-wrap">
          <button onClick={(event) => handleAddClick()}>
            <FontAwesomeIcon icon={faPlus} className="btn-add-message" />
            Add Holder
          </button>
        </div>
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={filterData(holders)}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              editClick={handleEditClick}
              paginationEnabled={true}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
      <ProfessorshipsModal
        modalOpen={modalOpen}
        modalAction={modalAction}
        closeModal={(event) => setModalOpen(false)}
        holderInfo={holderInfo}
        refreshHolders={handleRefreshHolders}
      />
    </div>
  );
};

export default ProfessorshipHoldersAdmin;
