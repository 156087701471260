import React, { useState, useEffect, useCallback } from "react";
import SortTable from "../utils/SortTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import UserManagementModal from "./UserManagementModal";
import Config from "../config/Config";
import Loader from "../utils/Loader";
import axios from "axios";
import { Roles } from "../config/Apps";

const columns = [
  {
    Header: "NetID",
    accessor: "netid",
    sticky: "left",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Roles",
    accessor: "roles",
    type: "role-json",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
    type: "date",
  },
  {
    Header: "End Date",
    accessor: "end_date",
    type: "date",
  },
  {
    Header: "Created Date",
    accessor: "created_date",
    type: "date",
  },
  {
    Header: "Created By",
    accessor: "created_by",
  },
  {
    Header: "Modified Date",
    accessor: "modified_date",
    type: "date",
  },
  {
    Header: "Modified By",
    accessor: "modified_by",
  },
];

const defaultSorted = [{ id: "netid", desc: false }];

const UserManagement = () => {
  let { ut_users_api_root, ut_users_api_key } = Config;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [users, setUsers] = useState([]);
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [filterText, setFilterText] = useState("");
  const [filterRole, setFilterRole] = useState("init");

  const handleRefreshUsers = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    axios
      .get(ut_users_api_root + "/users", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": ut_users_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the users: " + response.data.error,
          });
        } else if (response.data.length > 0) {
          setUsers(response.data);
          setAPIMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no users.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAPIMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving users!",
        });
      });
  }, [ut_users_api_root, ut_users_api_key]);

  useEffect(() => {
    handleRefreshUsers();
  }, [handleRefreshUsers]);

  const handleEditClick = (event, row) => {
    setUserInfo(row.values);
    setModalAction("Edit");
    setModalOpen(true);
  };

  const handleAddClick = (event) => {
    setUserInfo({});
    setModalAction("Add");
    setModalOpen(true);
  };

  return (
    <div className="message-center-container">
      <div className="tool-title">User Management</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The User Management tool can be used to manage user authentication and
          authorization in the Universal Tool system.
        </div>
        <div className="tool-feature-list">
          <div className="tool-feature-label">Tool Features:</div>
          <ul>
            <li>Users can be added and updated.</li>
          </ul>
        </div>
      </div>
      <div className="filtering-container">
        <div className="filter-title">Filtering</div>
        <div className="filter-inputs-wrap">
          <div className="input-icon-wrap">
            <div className="input-icon">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              value={filterText}
              type="text"
              required
              placeholder="Search name fields"
              autoComplete="off"
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
          <div className="custom-dd-wrap">
            <select
              value={filterRole}
              onChange={(e) => setFilterRole(e.target.value)}
            >
              <option value="init" key="init" disabled>
                Select a role.
              </option>
              {Roles.map((role) => (
                <option value={role.name} key={role.name}>
                  {role.dispName}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button
          onClick={(event) => {
            setFilterText("");
            setFilterRole("init");
          }}
        >
          Clear Filters
        </button>
      </div>
      <div className="table-container">
        <div className="btn-add-message-wrap">
          <button onClick={(event) => handleAddClick()}>
            <FontAwesomeIcon icon={faPlus} className="btn-add-message" />
            Add User
          </button>
        </div>
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={users
                .filter((user) => {
                  return (
                    (user["netid"] &&
                      user["netid"]
                        .toString()
                        .toLowerCase()
                        .includes(filterText.toLowerCase())) ||
                    (user["name"] &&
                      user["name"]
                        .toString()
                        .toLowerCase()
                        .includes(filterText.toLowerCase()))
                  );
                })
                .filter((user) => {
                  if (filterRole === "init") return user;
                  else
                    return user.roles.some(
                      (role) =>
                        role.toString().toLowerCase() ===
                        filterRole.toString().toLowerCase()
                    );
                })}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              editClick={handleEditClick}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
      <UserManagementModal
        modalOpen={modalOpen}
        modalAction={modalAction}
        closeModal={(event) => {
          setUserInfo({});
          setModalOpen(false);
        }}
        userInfo={userInfo}
        refreshUsers={(event) => handleRefreshUsers()}
      />
    </div>
  );
};

export default UserManagement;
