import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

const Loader = ({ message }) => {
  let { loading, error, messageText } = message;
  return (
    <div className="loader-container">
      <div className="loader-wrap">
        <div className="loading-message">{messageText}</div>
        <div className="loading-icon">
          {loading ? (
            <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
          ) : null}
          {error ? <FontAwesomeIcon icon={faExclamationTriangle} /> : null}
        </div>
      </div>
    </div>
  );
};

export default Loader;
