import React from "react";

const Unauthorized = () => {
  return (
    <div className="main-content-outer align-middle">
      <div className="main-content-inner">
        <div className="unauthorized-msg">
          <span className="bold">You are unauthorized to view this page.</span>
          <br></br>
          <br></br>If you have any questions about the Universal Tooling site,
          or require access, please email us at{" "}
          <a className="mailto" href="mailto:mis-web-dev@northwestern.edu">
            mis-web-dev@northwestern.edu
          </a>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
