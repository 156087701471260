import React from "react";
import ToolMenu from "../../utils/ToolMenu";
import UsersSearchAdmin from "./UsersSearchAdmin";
import { Route, Switch } from "react-router-dom";

const HomeFAOUsersSearch = () => {
  return (
    <div className="main-content-outer">
      <div className="main-content-inner constrain-1260">
        <ToolMenu app="faoUsersSearch" appURL="/fao/users-search" />
        <div className="app-content-container">
          <Switch>
            <Route
              exact
              path="/fao/users-search"
              component={UsersSearchAdmin}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default HomeFAOUsersSearch;
