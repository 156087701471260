export const MissingTypes = [
  {
    value: "1",
    dispValue: "Missing Faculty EmplID",
  },
  {
    value: "2",
    dispValue: "Missing Faculty NetIDs",
  },
  {
    value: "3",
    dispValue: "Missing Faculty SSNs",
  },
  {
    value: "4",
    dispValue: "Missing Faculty Email",
  },
  {
    value: "5",
    dispValue: "Missing Compensation Plan",
  },
  {
    value: "6",
    dispValue: "Missing Birth Date",
  },
  {
    value: "7",
    dispValue: "Missing NPI",
  },
  {
    value: "8",
    dispValue: "Bad Home Address",
  },
  {
    value: "9",
    dispValue: "Missing Photos",
  },
  {
    value: "10",
    dispValue: "Missing Research Sponsors",
  },
  {
    value: "11",
    dispValue: "Missing EmplID, NetID, or FAO Email",
  },
];
