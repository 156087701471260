import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import SortTable from "../../utils/SortTable";
import Config from "../../config/Config";
import Loader from "../../utils/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TitleGroupsModal from "./TitleGroupsModal";

const columns = [
  {
    Header: "Faculty ID",
    accessor: "FACULTY_ID",
    sticky: "left",
  },
  {
    Header: "Name",
    accessor: "NAME",
  },
  {
    Header: "Start Date",
    accessor: "START_DATE",
    type: "date",
  },
  {
    Header: "End Date",
    accessor: "END_DATE",
    type: "date",
  },
  {
    Header: "ID",
    accessor: "ID",
  },
  {
    Header: "Admin Title",
    accessor: "ADMIN_TITLE",
  },
  {
    Header: "Area",
    accessor: "AREA",
  },
  {
    Header: "Group ID",
    accessor: "GROUP_ID",
  },
];

const defaultSorted = [{ id: "FACULTY_ID", desc: false }];

const TitleGroupsAdmin = () => {
  let { fao_admin_api_root, fao_admin_api_key } = Config;
  const [apiMessage, setAPIMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [titleGroupTypesMessage, setTitleGroupTypesMessage] = useState({
    loading: true,
    error: false,
    ready: false,
    messageText: "Loading...",
  });
  const [titleGroups, setTitleGroups] = useState([]);
  const [titleGroupTypes, setTitleGroupTypes] = useState([]);
  const [filterGroup, setFilterGroup] = useState("init");
  const [modalAction, setModalAction] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [titleGroupsInfo, setTitleGroupsInfo] = useState([]);

  useEffect(() => {
    const transformTitleGroupTypes = (_titleGroupTypes) =>
      _titleGroupTypes.map((_titleGroupType) => {
        return {
          name: _titleGroupType.GROUP_ID,
          dispName: `${_titleGroupType.GROUP_NAME} (${_titleGroupType.GROUP_ID})`,
        };
      });

    axios
      .get(fao_admin_api_root + "/faoadmin/titles/groups/types", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setTitleGroupTypesMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the title group types: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setTitleGroupTypes(transformTitleGroupTypes(response.data.data));
          setTitleGroupTypesMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setTitleGroupTypesMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no title group types.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setTitleGroupTypesMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving title group types!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key]);

  const handleRefreshTitleGroups = useCallback(() => {
    setAPIMessage({
      loading: true,
      error: false,
      ready: false,
      messageText: "Loading...",
    });

    axios
      .get(fao_admin_api_root + "/faoadmin/titles/groups", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fao_admin_api_key,
        },
      })
      .then((response) => {
        if (response.data.error) {
          // Internal Error
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText:
              "There was an error retrieving the title groups: " +
              response.data.error,
          });
        } else if (response.data.data.length > 0) {
          setTitleGroups(response.data.data);
          setAPIMessage({
            loading: false,
            error: false,
            ready: true,
            messageText: "",
          });
        } else {
          setAPIMessage({
            loading: false,
            error: true,
            ready: false,
            messageText: "There are currently no title groups.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAPIMessage({
          loading: false,
          error: true,
          ready: false,
          messageText: "Error retrieving title groups!",
        });
      });
  }, [fao_admin_api_root, fao_admin_api_key]);

  useEffect(() => {
    handleRefreshTitleGroups();
  }, [handleRefreshTitleGroups]);

  const handleAddClick = (event) => {
    setTitleGroupsInfo({});
    setModalAction("Add");
    setModalOpen(true);
  };

  const handleEditClick = (event, row) => {
    setTitleGroupsInfo(row.values);
    setModalAction("Edit");
    setModalOpen(true);
  };

  return (
    <div className="title-groups-container">
      <div className="tool-title">FAO Title Groups Admin</div>
      <div className="tool-description-container">
        <div className="tool-description-label">Tool Description:</div>
        <div className="tool-description">
          The FAO Titles Groups Admin tool can be used to view title groups that
          are applied to faculty members.
        </div>
        <div className="tool-feature-list">
          <div className="tool-feature-label">Tool Features:</div>
          <ul>
            <li>
              Title groups are read-only, and can be filtered by group type.
            </li>
          </ul>
        </div>
      </div>
      <div className="filtering-container">
        <div className="filter-title">Filtering</div>
        <div className="filter-inputs-wrap one">
          <div className="custom-dd-wrap">
            <select
              value={filterGroup}
              onChange={(e) => setFilterGroup(e.target.value)}
            >
              {titleGroupTypesMessage.ready ? (
                <>
                  <option value="init" key="init" disabled>
                    Select a title group type.
                  </option>
                  {titleGroupTypes.map((titleGroupType) => (
                    <option
                      value={titleGroupType.name}
                      key={titleGroupType.name}
                    >
                      {titleGroupType.dispName}
                    </option>
                  ))}
                </>
              ) : (
                <option value="init" key="init" disabled>
                  Loading...
                </option>
              )}
            </select>
          </div>
        </div>
        <button
          onClick={(event) => {
            setFilterGroup("init");
          }}
        >
          Clear Filters
        </button>
      </div>
      <div className="table-container">
        <div className="btn-add-message-wrap">
          <button onClick={(event) => handleAddClick()}>
            <FontAwesomeIcon icon={faPlus} className="btn-add-message" />
            Add Title
          </button>
        </div>
        <div className="table-wrap">
          {apiMessage.ready ? (
            <SortTable
              sortData={titleGroups.filter((titleGroup) => {
                if (filterGroup === "init") return titleGroup;
                else if (titleGroup.GROUP_ID.toString() === filterGroup)
                  return titleGroup;
                else return false;
              })}
              sortColumns={columns}
              sortDefaultSorted={defaultSorted}
              paginationEnabled={true}
              editClick={handleEditClick}
            />
          ) : (
            <Loader message={apiMessage} />
          )}
        </div>
      </div>
      <TitleGroupsModal
        modalOpen={modalOpen}
        modalAction={modalAction}
        closeModal={(event) => setModalOpen(false)}
        titleGroupsInfo={titleGroupsInfo}
        refreshTitleGroups={handleRefreshTitleGroups}
        titleGroupTypes={titleGroupTypes}
      />
    </div>
  );
};

export default TitleGroupsAdmin;
