import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./components/core/App";
import * as serviceWorker from "./serviceWorker/serviceWorker";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import Config from "./components/config/Config";

const msalInstance = new PublicClientApplication(Config.msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
