export const MessageTypes = [
  {
    value: "warning",
    dispValue: "Warning",
    color: "#f0ad4e",
  },
  {
    value: "info",
    dispValue: "Info",
    color: "#5bc0de",
  },
  {
    value: "danger",
    dispValue: "Danger",
    color: "#d9534f",
  },
];
