import React from "react";
import ToolMenu from "../../utils/ToolMenu";
import TitlesAdmin from "./TitlesAdmin";
import TitleTypesAdmin from "./TitleTypesAdmin";
import TitleGroupsAdmin from "./TitleGroupsAdmin";
import { Route, Switch } from "react-router-dom";

const HomeFAOTitles = () => {
  return (
    <div className="main-content-outer">
      <div className="main-content-inner constrain-1260">
        <ToolMenu app="faoTitles" appURL="/fao/titles" />
        <div className="app-content-container">
          <Switch>
            <Route exact path="/fao/titles" component={TitlesAdmin} />
            <Route path="/fao/titles/title-types" component={TitleTypesAdmin} />
            <Route
              path="/fao/titles/title-groups"
              component={TitleGroupsAdmin}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default HomeFAOTitles;
