import React from "react";

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <div className="coming-soon-msg">
        <span className="bold">Coming Soon!</span>
        <br></br>
        <br></br>
        We are currently developing this module.
      </div>
    </div>
  );
};

export default ComingSoon;
