import React from "react";

const NotFound = () => {
  return (
    <div className="main-content-outer align-middle">
      <div className="main-content-inner">Page not found.</div>
    </div>
  );
};

export default NotFound;
